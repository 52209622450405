import { css } from "@emotion/react";

export const HeaderCSS = css`
    width: 100%;
    height: 60px;
    padding: 0 30px;
    border-bottom: 1px solid #abb7c0;
    position: fixed;
    background: #fff;
    top: 0;
    z-index:100;
    .how-to-link,.glossary-link {
      position: absolute;
      right: 15em;
      top: 20px;
      font-size: 1.1em;
      font-weight:500;
    }
    .glossary-link{
      right:10em;
    }
    .how-to-link:after,.glossary-link:after  {
      content: '';
      display: block;
      border-bottom: 2px solid #305A80;
    }
    .companySwitcher li.dropdownListItem {
        flex: 0 0 100%;
    }
    #qokoon_title {
        width: 190px;
        position: absolute;
        left: 5%;
        margin: 0;
        top: 5px;
    }

    .header-right {
    position: absolute;
    right: 5%;
    top: 12px;
    display:flex;
    align-items: center;
    justify-content: space-between;
        svg {
            width:32px;
            height:33px;
            border: 1px solid #000;
            border-radius: 2px;
            cursor: pointer;
        }
        .dropdownWrapper.companySwitcher{
          margin-right:30px;
        }

        .user-menu {
            height: 76px;
            width: 154px;
            position: absolute;
            right: 5%;
            top: 20px;
            padding: 0;
            border: 1px solid #979797;
            border-radius: 2px;
            background-color: #ffffff;
            box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);

            li {
                border-bottom: 1px solid #d8dfe7;
                padding: 0.6em;
                padding-left: 1.2em;
                color: #305a80;
                font-family: Roboto;
                font-size: 15px;
                letter-spacing: 0.13px;
                line-height: 18px;
                cursor: pointer;
            }

            li:first-of-type {
                color: #39505e;
                cursor: initial;
                text-decoration: none;
            }
        }
    }

`;