import Axios from 'axios';
import {cachedGet, del, get, patch, post, put} from "./httpClient";

const addToLocalStorage = (key, data) => {
  const existingData = JSON.parse(localStorage.getItem(key) || '{}');
  if (data) {
    localStorage.setItem(key, JSON.stringify({ ...existingData, ...data }));
  } else {
    localStorage.setItem(key, '{}');
  }
};
const getFromLocalStorage = key => JSON.parse(localStorage.getItem(key) || '{}');

const isEmpty = obj =>  typeof(obj) !== undefined || Object.keys(obj).length === 0;

export {
  addToLocalStorage,
  getFromLocalStorage,
  isEmpty,
}



Axios.defaults.headers.post.Accept = 'application/json, text/plain, */*';
Axios.defaults.headers.post['Content-Type'] = 'application/json';

Axios.defaults.headers.put.Accept = 'application/json, text/plain, */*';
Axios.defaults.headers.put['Content-Type'] = 'application/json';
// eslint-disable-next-line
export default {
  get, post, put, patch, cachedGet, del, Axios,
};