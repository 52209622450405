import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine, Cell
} from 'recharts';
import Loader from "../reusables/Loader";
import ErrorResponse from "../error-response/error-response";


const LINE_CHART_HEIGHT = 360;
const CustomizedAxisTick =(props)=> {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text fontSize={'1em'} x={0} y={0} dy={16} textAnchor="end" fill="#305A80" transform="rotate(-35)">
        {payload.value.substr(0,22)}{payload.value.length>22?'...':''}
      </text>
    </g>
  );
}
const CustomTooltip = (props) => {
  const { active, payload, label,formatter } = props;
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="tooltip-heading">{`${label}`}</p>
        <div style={{color:payload[1].stroke}} >
          <span className="tooltip-label">Amount : </span>
          <span className="tooltip-value">{formatter(payload[1].value)}</span>
        </div>
      </div>
    );
  }

  return null;
};
const CfGenerationWaterfallChart=(props)=> {
  const {data,loading,error,formatter,maxWidth='700px',barSize=15} = props;
  if(loading) {
    return <div style={{margin:'1em 0 2em 0'}}><Loader type={'pulse'} size={10}/></div>
  }
  if(error){
    return (<ErrorResponse error={error}/>)
  }
  if(data.length > 0 && !loading) {
    return (
      <div className={`simple-charts-wrapper`} style={{fontSize: '0.8em', width: '100%', maxWidth: maxWidth}}>
        <ResponsiveContainer height={LINE_CHART_HEIGHT}>
          <BarChart
            height={LINE_CHART_HEIGHT}
            data={data}
            margin={{top: 5, right: 30, left: 20, bottom: 5}}
            barSize={barSize}
          >
            <XAxis interval={0} fontSize={9} height={125} dataKey="cat" type={'category'} tickSize={10} tick={<CustomizedAxisTick/>} padding={{left: 5, right: 5}}/>
            <YAxis tickFormatter={(value)=> formatter(value)}/>
            <Tooltip content={<CustomTooltip/>} formatter={(value)=> formatter(value)} />
            <ReferenceLine y={0} stroke="#000" strokeDasharray="3 3" />
            <Bar dataKey="sum" stackId="a" fill="transparent" />
            <Bar dataKey="value" stackId="a" fill="#3571CA"  >
              {data.map((entry, index) => {
                if (entry.cat === 'Cashflow from Operations'){
                  return (
                    <Cell key={`cell-${index}`} fill={entry.value > 0 ? '#2ca02c' : '#d62728'}/>
                  )
                }
                else if(entry.cat === 'Free Cashflow'){
                  return (<Cell key={`cell-${index}`} fill={'#3366cc'}/>)
                }
                else if(entry.cat === 'Cashflow Generation'){
                  return (<Cell key={`cell-${index}`} fill={'#3366cc'}/>)
                }
                else {
                  return (<Cell key={`cell-${index}`} fill={entry.value > 0 ? '#2ca02c' : '#d62728'}/>)
                }}
              )}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
  return (<div className="chart-error error-icon"><span>Data not available</span></div>)
}
export default CfGenerationWaterfallChart;
