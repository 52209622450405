import React from 'react';
import {formatCurrency} from "../analysis-helper";
import ErrorResponse from "../error-response/error-response";
import PnlSummaryBarChart from "../pnl-summary-bar-chart";
import WaterfallChart from "../waterfall-chart";
import Loader from "../reusables/Loader";
import ReactHtmlParser from "react-html-parser";
import LineChartDoubleYAxis from "../line-chart-double-y-axis";

const PnlSummary=({props})=>{
const {
    paramCategory,
    overallValueMonthLoading,
    overallValueMonth,
    company,
    overallValueMonthError,
    overallValueYTDLoading,
    overallValueYTDError,
    overallValueYTD,
    overallValueLTMLoading,
    overallValueLTMError,
    summaryMonth,
    summaryMonthLoading,
    summaryMonthError,
    summaryYTD,
    summaryYTDLoading,
    summaryYTDError,
    summaryLTM,
    summaryLTMLoading,
    summaryLTMError,
    changeEbitdaMonthLoading,
    changeEbitdaMonthError,
    changeEbitdaMonth,
    changeEbitdaYTDLoading,
    changeEbitdaYTDError,
    changeEbitdaYTD,
    overallValueLTM,
    overallEbitdaContriWriteupLoading,
    overallEbitdaContriWriteupError,
    overallEbitdaContriWriteup,
    overallEbitdaEvolutionMonthLoading,
    overallEbitdaEvolutionMonthError,
    overallEbitdaEvolutionMonth,
    changeEbitdaMarginMonthLoading,
    changeEbitdaMarginMonthError,
    changeEbitdaMarginMonth,
    changeEbitdaMarginYTDLoading,
    changeEbitdaMarginYTDError,
    changeEbitdaMarginYTD,
    overallEbitdaEvolutionLTMLoading,
    overallEbitdaEvolutionLTMError,
    overallEbitdaEvolutionLTM,
    salesEvolutionMonthLoading,
    salesEvolutionMonthError,
    salesEvolutionMonth,
    salesEvolutionLTMLoading,
    salesEvolutionLTMError,
    salesEvolutionLTM,
    costOfSalesEvolutionMonth,
    costOfSalesEvolutionMonthError,
    costOfSalesEvolutionMonthLoading,
    costOfSalesEvolutionLTMLoading,
    costOfSalesEvolutionLTMError,
    costOfSalesEvolutionLTM,
    grossProfitEvolutionMonthLoading,
    grossProfitEvolutionMonthError,
    grossProfitEvolutionMonth,
    grossProfitEvolutionLTMLoading,
    grossProfitEvolutionLTMError,
    grossProfitEvolutionLTM,
    sgnaEvolutionMonthLoading,
    sgnaEvolutionMonthError,
    sgnaEvolutionMonth,
    sgnaEvolutionLTMLoading,
    sgnaEvolutionLTMError,
    sgnaEvolutionLTM,
    netIncomeEvolutionMonthLoading,
    netIncomeEvolutionMonthError,
    netIncomeEvolutionMonth,
    netIncomeEvolutionLTMLoading,
    netIncomeEvolutionLTMError,
    netIncomeEvolutionLTM
    }= props;
  const currency= company.currency;

    return (
      <div>
        <div className={`line-charts-wrapper`}>
          {!overallValueMonthLoading && <div className='lineChartHeading' style={{textAlign: 'center'}}>
              <span className='lineChartHeadingLabel'>Month EBITDA {parseInt(overallValueMonth) >= 0 ?
                <span
                  className='pieChartHeadingLabelAmount'>{formatCurrency(parseInt(overallValueMonth), currency)}</span> :
                <span className='pieChartHeadingLabelAmount'
                      style={{color: 'red'}}>{formatCurrency(parseInt(overallValueMonth), currency)}</span>}
              </span>
            <span>{overallValueMonthError && <ErrorResponse error={overallValueMonthError}/>}</span>
          </div>}
          {!overallValueYTDLoading && <div className='lineChartHeading' style={{textAlign: 'center'}}>
               <span className='lineChartHeadingLabel'>YTD EBITDA {parseInt(overallValueYTD) >= 0 ?
                 <span
                   className='pieChartHeadingLabelAmount'>{formatCurrency(parseInt(overallValueYTD), currency)}</span> :
                 <span className='pieChartHeadingLabelAmount'
                       style={{color: 'red'}}>{formatCurrency(parseInt(overallValueYTD), currency)}</span>}
              </span>
            <span>{overallValueYTDError && <ErrorResponse error={overallValueYTDError}/>}</span>

          </div>}
          {!overallValueLTMLoading && <div className='lineChartHeading' style={{textAlign: 'center'}}>
               <span className='lineChartHeadingLabel'>LTM EBITDA {parseInt(overallValueLTM) >= 0 ?
                 <span
                   className='pieChartHeadingLabelAmount'>{formatCurrency(parseInt(overallValueLTM), currency)}</span> :
                 <span className='pieChartHeadingLabelAmount'
                       style={{color: 'red'}}>{formatCurrency(parseInt(overallValueLTM), currency)}</span>}
              </span>
            <span>{overallValueLTMError && <ErrorResponse error={overallValueLTMError}/>}</span>
          </div>}
        </div>
        <hr className='separator-line' style={{margin: '2em 0 0em 0'}}/>
        <div className={`line-charts-wrapper`}>
          {<div className='lineChartHeading' style={{flex: '0 0 32%'}}>
            <span className='lineChartHeadingLabel'>Month P&L Summary</span>
          </div>}
          {<div className='lineChartHeading' style={{flex: '0 0 32%'}}>
            <span className='lineChartHeadingLabel'>YTD P&L Summary</span>
          </div>}
          {<div className='lineChartHeading' style={{flex: '0 0 32%'}}>
            <span className='lineChartHeadingLabel'>LTM P&L Summary</span>
          </div>}
        </div>

        <div className={`line-charts-wrapper`}>
          {<div className={`line-chart-container`} style={{flex: '0 0 32%'}}>
            <PnlSummaryBarChart
              data={summaryMonth}
              loading={summaryMonthLoading}
              error={summaryMonthError}
              formatter={(value) => formatCurrency(value, currency)}
            />
          </div>}
          {<div className={`line-chart-container`} style={{flex: '0 0 32%'}}>
            <PnlSummaryBarChart
              data={summaryYTD}
              loading={summaryYTDLoading}
              error={summaryYTDError}
              formatter={(value) => formatCurrency(value, currency)}
            />
          </div>}
          {<div className={`line-chart-container`} style={{flex: '0 0 32%'}}>
            <PnlSummaryBarChart
              data={summaryLTM}
              loading={summaryLTMLoading}
              error={summaryLTMError}
              formatter={(value) => formatCurrency(value, currency)}
            />
          </div>}
        </div>
        <hr className='separator-line' style={{margin: '2em 0 0em 0'}}/>
        {/*Pnl generation charts*/}

        <div className={`line-charts-wrapper`}>
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Contributors to EBITDA Change Month</span>
          </div>}
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Contributors to EBITDA Change  YTD</span>
          </div>}
        </div>
        <div className={`line-charts-wrapper`}>
          <div className={`line-chart-container`}>
            <WaterfallChart
              dataValue={paramCategory}
              formatter={(value) => formatCurrency(value, currency)}
              loading={changeEbitdaMonthLoading}
              error={changeEbitdaMonthError}
              data={changeEbitdaMonth}/>
          </div>
          <div className={`line-chart-container`}>
            <WaterfallChart
              dataValue={paramCategory}
              formatter={(value) => formatCurrency(value, currency)}
              loading={changeEbitdaYTDLoading}
              error={changeEbitdaYTDError}
              data={changeEbitdaYTD}/>
          </div>
        </div>

        <div className={`line-charts-wrapper`}>
          {overallEbitdaContriWriteupLoading &&
           <div className={`align-loader`}><Loader type={'pulse'} size={10}/></div>}
          <div className={`writeups-container`} style={{width: '50%'}}>
            {(!overallEbitdaContriWriteupLoading && !overallEbitdaContriWriteupError) &&
             <div className='writeup-wrapper'>
               {ReactHtmlParser(overallEbitdaContriWriteup.ebitda_monthly)}
             </div>
            }
          </div>
          <div className={`writeups-container`} style={{width: '50%'}}>
            {(!overallEbitdaContriWriteupLoading && !overallEbitdaContriWriteupError) &&
             <div className='writeup-wrapper'>
               {ReactHtmlParser(overallEbitdaContriWriteup.ebitda_yearly)}
             </div>
            }
          </div>
          {overallEbitdaContriWriteupError && <ErrorResponse error={overallEbitdaContriWriteupError}/>}
        </div>

        <hr className='separator-line' style={{margin: '2em 0 0em 0'}}/>

        <div className={`line-charts-wrapper`}>
          <div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Contributors to EBITDA Margin Change Month</span>
          </div>
          <div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Contributors to EBITDA Margin Change  YTD</span>
          </div>
        </div>

        <div className={`line-charts-wrapper`}>
          <div className={`line-chart-container`}>
            <WaterfallChart
              dataValue={paramCategory}
              formatter={(value) => `${parseFloat(value).toFixed(1)} %`}
              loading={changeEbitdaMarginMonthLoading}
              error={changeEbitdaMarginMonthError}
              data={changeEbitdaMarginMonth}/>
          </div>
          <div className={`line-chart-container`}>
            <WaterfallChart
              dataValue={paramCategory}
              formatter={(value) => `${parseFloat(value).toFixed(1)} %`}
              loading={changeEbitdaMarginYTDLoading}
              error={changeEbitdaMarginYTDError}
              data={changeEbitdaMarginYTD}/>
          </div>
        </div>

        <div className={`line-charts-wrapper`}>
          {overallEbitdaContriWriteupLoading &&
           <div className={`align-loader`}>
             <Loader type={'pulse'} size={10}/></div>}
          {<div className={`writeups-container`} style={{width: '50%'}}>
            {(!overallEbitdaContriWriteupLoading && !overallEbitdaContriWriteupError) &&
             <div className='writeup-wrapper'>
               {ReactHtmlParser(overallEbitdaContriWriteup.ebitda_margin_monthly)}
             </div>
            }
          </div>}
          {<div className={`writeups-container`} style={{width: '50%'}}>
            {(!overallEbitdaContriWriteupLoading && !overallEbitdaContriWriteupError) &&
             <div className='writeup-wrapper'>
               {ReactHtmlParser(overallEbitdaContriWriteup.ebitda_margin_yearly)}
             </div>
            }
          </div>}
          {overallEbitdaContriWriteupError && <ErrorResponse error={overallEbitdaContriWriteupError}/>}
        </div>
        <hr className='separator-line' style={{margin: '2em 0 0em 0'}}/>

        <div className={`line-charts-wrapper`}>
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>EBITDA Evolution Monthly</span>
          </div>}
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>EBITDA Evolution LTM</span>
          </div>}
        </div>


        <div className={`line-charts-wrapper`}>
          {<div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              percentageOf={'EBITDA as % of sales'}
              dataValue={'EBITDA'}
              showGrid
              formatter={(value) => formatCurrency(value, currency)}
              loading={overallEbitdaEvolutionMonthLoading}
              error={overallEbitdaEvolutionMonthError}
              data={overallEbitdaEvolutionMonth}/>
          </div>}
          {<div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              percentageOf={'EBITDA as % of sales'}
              dataValue={'EBITDA'}
              showGrid
              formatter={(value) => formatCurrency(value, currency)}
              loading={overallEbitdaEvolutionLTMLoading}
              error={overallEbitdaEvolutionLTMError}
              data={overallEbitdaEvolutionLTM}/>
          </div>}
        </div>
        <hr className='separator-line' style={{margin: '2em 0 0em 0'}}/>
        <div className={`line-charts-wrapper`}>
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Sales Evolution Monthly</span>
          </div>}
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Sales Evolution LTM</span>
          </div>}
        </div>


        <div className={`line-charts-wrapper`}>
            <div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              percentageOf={'Sales as % of sales'}
              dataValue={'Sales'}
              showGrid
              showLegend
              formatter={(value) => formatCurrency(value, currency)}
              loading={salesEvolutionMonthLoading}
              error={salesEvolutionMonthError}
              data={salesEvolutionMonth}/>
          </div>
          <div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              percentageOf={'Sales as % of sales'}
              dataValue={'Sales'}
              showGrid
              showLegend
              formatter={(value) => formatCurrency(value, currency)}
              loading={salesEvolutionLTMLoading}
              error={salesEvolutionLTMError}
              data={salesEvolutionLTM}/>
          </div>
        </div>
        {(costOfSalesEvolutionMonth.length > 0 || costOfSalesEvolutionMonthError) &&
         <hr className='separator-line' style={{margin: '2em 0 0em 0'}}/>}
        {
          (costOfSalesEvolutionMonth.length > 0 || costOfSalesEvolutionMonthError) &&
          <div className={`line-charts-wrapper`}>
            {<div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>Monthly Cost of Sales Evolution</span>
            </div>}
            {<div className='lineChartHeading'>
              <span className='lineChartHeadingLabel'>LTM Cost of Sales Evolution</span>
            </div>}
          </div>
        }
        {(costOfSalesEvolutionMonth.length > 0 || costOfSalesEvolutionMonthError) &&
         <div className={`line-charts-wrapper`}>
           <div className={`line-chart-container`}>
             <LineChartDoubleYAxis
               percentageOf={'Cost of Sales as % of sales'}
               dataValue={'Cost of Sales'}
               showGrid
               showLegend
               formatter={(value) => formatCurrency(value, currency)}
               loading={costOfSalesEvolutionMonthLoading}
               error={costOfSalesEvolutionMonthError}
               data={costOfSalesEvolutionMonth}/>
           </div>
           <div className={`line-chart-container`}>
             <LineChartDoubleYAxis
               percentageOf={'Cost of Sales as % of sales'}
               dataValue={'Cost of Sales'}
               showGrid
               showLegend
               formatter={(value) => formatCurrency(value, currency)}
               loading={costOfSalesEvolutionLTMLoading}
               error={costOfSalesEvolutionLTMError}
               data={costOfSalesEvolutionLTM}/>
           </div>
         </div>}

        <hr className='separator-line' style={{margin: '2em 0 0em 0'}}/>
        <div className={`line-charts-wrapper`}>
            <div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Monthly Gross Profit Evolution</span>
          </div>
          <div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>LTM Gross Profit Evolution</span>
          </div>
        </div>


        <div className={`line-charts-wrapper`}>
          <div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              percentageOf={'Gross Profit as % of sales'}
              dataValue={'Gross Profit'}
              showGrid
              formatter={(value) => formatCurrency(value, currency)}
              loading={grossProfitEvolutionMonthLoading}
              error={grossProfitEvolutionMonthError}
              data={grossProfitEvolutionMonth}/>
          </div>
          <div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              percentageOf={'Gross Profit as % of sales'}
              dataValue={'Gross Profit'}
              showGrid
              formatter={(value) => formatCurrency(value, currency)}
              loading={grossProfitEvolutionLTMLoading}
              error={grossProfitEvolutionLTMError}
              data={grossProfitEvolutionLTM}/>
          </div>
        </div>
        <hr className='separator-line' style={{margin: '2em 0 0em 0'}}/>

        <div className={`line-charts-wrapper`}>
          <div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Monthly SG&A Evolution</span>
          </div>
          <div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>LTM SG&A Evolution</span>
          </div>
        </div>


        <div className={`line-charts-wrapper`}>
          <div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              percentageOf={'SG&A as % of sales'}
              dataValue={'SG&A'}
              showGrid
              showLegend
              formatter={(value) => formatCurrency(value, currency)}
              loading={sgnaEvolutionMonthLoading}
              error={sgnaEvolutionMonthError}
              data={sgnaEvolutionMonth}/>
          </div>
          <div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              percentageOf={'SG&A as % of sales'}
              dataValue={'SG&A'}
              showGrid
              showLegend
              formatter={(value) => formatCurrency(value, currency)}
              loading={sgnaEvolutionLTMLoading}
              error={sgnaEvolutionLTMError}
              data={sgnaEvolutionLTM}/>
          </div>
        </div>
        <hr className='separator-line' style={{margin: '2em 0 0em 0'}}/>

        <div className={`line-charts-wrapper`}>
          <div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Monthly Net Income Evolution</span>
          </div>
          <div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>LTM Net Income Evolution</span>
          </div>
        </div>


        <div className={`line-charts-wrapper`}>
          <div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              percentageOf={'Net Income as % of sales'}
              dataValue={'Net Income'}
              showGrid
              formatter={(value) => formatCurrency(value, currency)}
              loading={netIncomeEvolutionMonthLoading}
              error={netIncomeEvolutionMonthError}
              data={netIncomeEvolutionMonth}/>
          </div>
          <div className={`line-chart-container`}>
            <LineChartDoubleYAxis
              percentageOf={'Net Income as % of sales'}
              dataValue={'Net Income'}
              showGrid
              formatter={(value) => formatCurrency(value, currency)}
              loading={netIncomeEvolutionLTMLoading}
              error={netIncomeEvolutionLTMError}
              data={netIncomeEvolutionLTM}/>
          </div>
        </div>
      </div>
    )
}
export default PnlSummary;