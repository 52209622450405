import {Link} from "react-router-dom";
//import settings_icon from "../img/settings-icon.png";
import {useDispatch} from "react-redux";
import xero_logo from "../img/xero_logo.png";
import sync_icon from "../img/sync-alt.svg";
import axios from "axios";
import React, {useEffect, useState} from "react";
import notify from "../notify/notify";
import Loader from "../reusables/Loader";
import crossIcon from '../img/cross-icon-red.svg';
import qokoon_symbol from '../img/qokoon-logo.png';
import {Mixpanel} from "../mixpanel";
import {timeSince} from "../util/dateHelper";
import Modal from "react-modal";

function CompanyRow({companiesList,deleteCallBack}) {
const [showModal,setShowModal] =useState(false);
const [deleteConfirmed,setDeleteConfirmed]=useState(false);
const [selectedCompanyForDelete,setSelectedCompanyForDelete]=useState({});
  const sortCompanyList =(list)=> {
    let companyList = list;
    const indexOfDemo = Array.isArray(list) ? list.findIndex((a)=> a.companyName ==='Demo Company'):-1;
    if(indexOfDemo !== -1){
      const dummyCompany = companyList[indexOfDemo];
      companyList = companyList.filter((company)=>
        company.companyName !== 'Demo Company'
      )
      companyList.sort((a, b) => a.companyName.localeCompare(b.companyName))
      companyList = [dummyCompany,...companyList];
    }
    return companyList;
  }
  const sortedCompanyData = sortCompanyList(companiesList);
  const updateCompany = async (companyId)=>{
    const headers = {
      'Content-Type': 'text/plain;charset=UTF-8',
    };
    return await axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}/company/${companyId}`,{companyId:companyId},{
      headers:headers,
      withCredentials: true
    })
  }

  const onDataSync= async (e,data)=>{
    const showWrapper=e.currentTarget.parentElement.parentElement.firstElementChild;
    showWrapper.style.display='block';
    const response = await updateCompany(data.companyId);
    if(response.status === 200){
      notify({type: 'SUCCESS', message: 'Data updated successfully'});
      Mixpanel.track('Company update successful',{'companyId':data.companyName});
      dispatch({type:'modifyCompanyDetails',payload: {response: response.data,companyId:data.companyId}});
    }
    else{
      Mixpanel.track('Company update failed',{'companyId':data.companyName});
      notify({type: 'ERROR', message: 'Something went wrong. Please try updating later'});
    }
    showWrapper.style.display='none';
  }
  const getConfirmation=()=>{
    setShowModal(true);
  }
  const deleteCompany= async(companyId)=>{
    const headers = {
      'Content-Type': 'text/plain;charset=UTF-8',
    };

    let parameters = {
      companyId
    };
    const response = await axios(`${process.env.REACT_APP_BACKEND_BASE_URL}/companies/${companyId}`, {
      method: "delete",
      data: parameters,
      withCredentials: true,
      headers:headers
    });
    return response;
  }
  const deleteCompanyClick =(e,data)=>{
    setSelectedCompanyForDelete(data);
    getConfirmation();
}
  useEffect( ()=>{
      if(deleteConfirmed && Object.keys(selectedCompanyForDelete).length >0 ) {
        deleteCompany(selectedCompanyForDelete.companyId).then(
          (response)=>{
            if(response.status === 204){
              notify({type:'success',message:`${selectedCompanyForDelete.companyName} deleted successfully!`});
              deleteCallBack();
            }
            else{
              notify({type:'error',message:`Something went wrong. Please try again later`});
              deleteCallBack();
            }
            setShowModal(false);
          }
        );
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedCompanyForDelete,deleteConfirmed]);

  const dispatch=useDispatch();
  return (
    <ul data-testid="companies-list" id="companies-list" className="company-table table">
      <li className="table-header">
        <span className="company-name">Company name</span>
        <span className="data-source">Data Source</span>
        <span className="last-updated">Last updated <span style={{paddingLeft:'0'}}>(Local Time)</span></span>
        <span className="actions">Actions</span>
      </li>
      {
        sortedCompanyData.map(data => {
          const updationDate = timeSince(Date.parse(data.updatedOn));
           return(
          <li className="company-data table-data" key={data.companyId}>
            <div className={`hide-row`}><Loader type={'pulse'} size={20}/> </div>
            <span className="company-name">{data.companyName}</span>
            <span className="data-source">{
              data.companyName === 'Demo Company' && <img id="qokoon-logo" src={qokoon_symbol} alt="" style={{width: '3em'}} />}
              {
                data.companyName !== 'Demo Company' && <img id="xero-logo" src={xero_logo} alt="" style={{width: '3em'}} />
            }</span>
            <span className="last-updated"><div style={{display:'flex',flexDirection:'column'}}><span style={{fontWeight:'500'}}>{`${updationDate}`} ago</span></div>
              <img id={`sync-img`} src={sync_icon} alt={"sync"} style={{width:'1.5em',cursor:'pointer'}} title={`Sync data`} onClick={(e)=>onDataSync(e,data)} /></span>
            <span className="actions">
                    <Link
                      onClick={()=>{
                        dispatch({type: 'selectCompany', payload: data});
                        dispatch(
                          {
                            type:'urlChange',
                            payload:{
                              section: 'insights',
                              sheet:null,
                              category:null,
                              subCategory: null,
                            }});
                        Mixpanel.track('Url Change',{'company':data.companyName,url:'/insights'});
                      }}
                      to={{
                        pathname:`/insights`,
                      }}
                      className="primary-btn small">
                      Key Insights
                    </Link>
                    <Link
                      onClick={(e)=>{
                        Mixpanel.track('Url Change',{'company':data.companyName,url:'/analyse'});
                        dispatch(
                          {
                            type:'urlChange',
                            payload:{
                              section: 'analyse',
                              sheet:'Profit and Loss',
                              category:'P&L Summary',
                              subCategory: null,
                            }});
                        dispatch({type: 'selectCompany', payload: data})}}
                      to={{
                        pathname:`/analyse/Profit%20and%20Loss`,
                      }}
                      className="primary-btn small"
                    >Analyse</Link>
                    <Link
                      onClick={()=>{
                        Mixpanel.track('Url Change',{'company':data.companyName,url:'/anomalies'});
                        dispatch(
                          {
                            type:'urlChange',
                            payload:{
                              section: 'anomalies',
                              sheet:null,
                              category: null,
                              subCategory: null,
                            }});
                        dispatch({type: 'selectCompany', payload: data})}}
                      to={{
                        pathname:`/anomalies`,
                      }}
                      className="primary-btn small"
                    >Anomalies</Link>
                    <Link
                      onClick={()=> {
                        Mixpanel.track('Url Change',{'company':data.companyName,url:'/reports'});
                        dispatch(
                          {
                            type:'urlChange',
                            payload:{
                              section: 'reports',
                              sheet:null,
                              category: null,
                              subCategory: null,
                            }});
                        dispatch({type: 'selectCompany', payload: data})}}
                      to={{
                        pathname:`/reports`,
                      }}
                      className="primary-btn small"
                    >Reports</Link>
              {data.companyName !== 'Demo Company'?
               <img className='delete-button' src={crossIcon} onClick={(e)=>deleteCompanyClick(e,data)} title={`delete company`} alt='delete'/>:
                <div style={{width:"22px",height:'33px'}}> </div>
              }
                      <span className='popoutTooltip'>Delete</span>
                </span>
          </li>)
        })
      }
      <Modal
        style={{
          content: {
          }
        }}
        className="simpleModal"
        overlayClassName="modalOverlay"
        isOpen={showModal}
        contentLabel="Add a company"
        data-testid="delete-company-modal"
      >
        <div className="modalHeader"><h2>Delete company</h2>
          <button
            className="modalCloseButton"
            onClick={()=>{
              setShowModal(false);
            }}>
          </button>
        </div>
        <div className='modalBody'>
            <p style={{fontSize:'1.1em'}}>Please confirm if you want to delete company {selectedCompanyForDelete.companyName}?</p>
          <div style={{paddingTop:'2em'}}>
            <button className='primary-btn' onClick={()=>{setDeleteConfirmed(true)}}>Delete</button>
            <button className='secondary-btn' style={{marginLeft:'2em'}} onClick={()=>{setShowModal(false)}}>Cancel</button>
          </div>
        </div>
      </Modal>
    </ul>
  )
}
export default CompanyRow;