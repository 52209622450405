/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import PulseLoader from "react-spinners/PulseLoader";
import ClipLoader from "react-spinners/ClipLoader";

const loader_css = css`
    position: relative;
    left: 50%;
    top: 35%;
`;

const inline_loader_css = css`
    position: relative;
`;

const loader_color = '#546472';

function Loader({type, size, inline,dataTestId='loader'}) {
    let LoaderType = null;

    switch (type) {
        case 'pulse':
            LoaderType = <PulseLoader loading={true} size={size} color={loader_color} />
            break;
    
        default:
            LoaderType = <ClipLoader loading={true} sizee={size} color={loader_color} /> 
            break;
    }

    return (
        <div css={inline ? inline_loader_css : loader_css} data-testid={dataTestId}>
            {LoaderType}
        </div>
    )
}

export default Loader;