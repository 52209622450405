import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine, Legend, Brush, Cell
} from 'recharts';
import Loader from "../reusables/Loader";
import ErrorResponse from "../error-response/error-response";


const LINE_CHART_HEIGHT = 360;

const CustomTooltip = (props) => {
  const { active, payload, label,formatter } = props;
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="tooltip-heading">{`${label}`}</p>
        { payload.map((item,index)=>{
          return (
            <div key={index} style={{color: item.fill}}>
              <span className="tooltip-label">{item.dataKey} &nbsp;</span>
              <span className="tooltip-value">{parseInt(item.value) === 0? formatter(0) :formatter(item.value)}</span>
            </div>
          )})}
      </div>
    );
  }

  return null;
};
const BarChartOneCategory=(props)=> {
  const {data,loading,error,formatter, dataKey, showBrush = true, showLegend = true} = props;
  if(loading) {
    return <div style={{margin:'1em 0 2em 0'}}><Loader type={'pulse'} size={10}/></div>
  }
  if(error){
    return (<ErrorResponse error={error}/>)
  }
  if(data.length > 0 && !loading) {
    return (
      <div className={`simple-charts-wrapper`} style={{fontSize: '0.8em', width: '100%', maxWidth: '700px'}}>
        <ResponsiveContainer height={LINE_CHART_HEIGHT}>
          <BarChart
            height={LINE_CHART_HEIGHT}
            data={data}
            margin={{top: 5, right: 30, left: 20, bottom: 5}}
            barSize={15}
          >
            <XAxis dataKey={dataKey} />
            <YAxis tickFormatter={(value)=> formatter(value)}/>
            <Tooltip content={<CustomTooltip/>} formatter={(value)=> formatter(value)} />
            <ReferenceLine y={0} stroke="#000" strokeDasharray="3 3" />
            <Bar dataKey="value" fill="#3366cc"  >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.value>0 ? '#3366cc':'#ff8042'} />
              ))}
            </Bar>
            {showBrush && <Brush />}
            {showLegend && <Legend/>}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
  return (<div></div>)
}
export default BarChartOneCategory;
