import React, {useEffect, useState} from "react";
import {formatCurrency, mapPnlSummaryEvolution,} from "../analysis-helper";
import useGet from "../hooks/use-get";
import {useSelector} from "react-redux"
import Loader from "../reusables/Loader";
import {
  Area,
  Brush,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import getSymbolFromCurrency from "currency-symbol-map";

const CustomTooltip = (props) => {
  const { active, payload, label,formatter } = props;
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="tooltip-heading">{`${label}`}</p>
        <div style={{color: payload[0].stroke}}>
          <span className="tooltip-label">{payload[0].name} : </span>
          <span className="tooltip-value">{formatter(payload[0].value)}</span>
        </div>
        {payload[1] &&
         <div style={{color: payload[1].stroke}}>
           <span className="tooltip-label">{payload[1].name} : </span>
           <span className="tooltip-value">{ Math.round(payload[1].value*10)/10}%</span>
         </div>
        }
      </div>
    );
  }
  return null;
};

const CustomLegend = (props) => {
  const { payload } = props;
  if (payload && payload.length) {
    return (
      <div className="custom-legend">
        <div style={{color: payload[0].color}}>
          <span className="tooltip-label">
            {payload[0].type ==='square'?
              <span style={{background:payload[0].color}} className='legend-square'> </span>:
              <span className='legend-line'> </span>}
            {props.text.length>0 ? getSymbolFromCurrency(props.text): payload[0].value}</span>
        </div>
        {payload[1] &&
         <div style={{color: payload[1].color,marginLeft:'2em'}}>
           <span style={{background:payload[1].color}} className='legend-line'> </span>
           <span className="tooltip-label">{payload[1].value}</span>
         </div>}
      </div>
    );
  }
  return null;
};

const LineChartDoubleYAxisTest =({data,
                               loading,
                               error,
                               showGrid=false,
                               formatter,
                               dataValue,
                               chartHeight =340,
                               showDot=true,
                               percentageOf,
                               showBrush=true,
                               showLegend=true,
                               tooltipTextForInsights='',
                               wrapperStyle = { fontSize: '12px', width: '500px',height:'340px' }})=>{
  if(loading) {
    return <div style={{margin:'1em 0 2em 0'}}><Loader type={'pulse'} size={10}/></div>
  }
  if(error){
    return (
      <div className="chart-error error-icon"><span>Error loading data. Try again later</span></div>)
  }
  if(data.length > 0 && !loading) {
    return (
      <div style={wrapperStyle} data-testid="LineChartDoubleYAxisWrapper">
          <ComposedChart
            height={chartHeight}
            data={data}
            width={500}
            margin={{top: 5, right: 10, left: 10, bottom: 5}}
          >
            {showGrid && <CartesianGrid strokeDasharray="3 3"/>}
            <XAxis dataKey="name" />
            <YAxis yAxisId="left" tickFormatter={(value)=> formatter(value)}/>
            <YAxis yAxisId="right" orientation="right" tickFormatter={(value, index)=> `${value} %`}/>
            <Tooltip content={<CustomTooltip />} formatter={(value)=> formatter(value)}/>
            {showLegend && (tooltipTextForInsights.length === 0) && <Legend wrapperStyle={{padding:'1em 0'}} />}
            {showLegend && (tooltipTextForInsights.length > 0) && <Legend content={<CustomLegend text={tooltipTextForInsights} formatter={(value)=> formatter(value)}/>} wrapperStyle={{padding:'1em 0'}} />}
            <Area yAxisId="left" type="monotone" dataKey={`${dataValue}`} activeDot={{ r: 5 }} stroke="#3571CA" fill="#3571CA" legendType={`square`} />
            {Object.keys(data[0]).length > 2 && <Line yAxisId="right" type="monotone" dataKey={percentageOf} stroke="#FE7602" dot={showDot} activeDot={{ r: 5 }} legendType={`line`} strokeWidth={3}/>}
            {showBrush && data.length > 12 && <Brush />}
          </ComposedChart>
      </div>
    )
  }
  return <div> </div>

}


const TestChart =()=>{
  const company = useSelector(state => state.selectedCompany);
  const selectedDate = useSelector(state => state.endDate);

  const [salesEvolutionMonth,setSalesEvolutionMonth]=useState({});

  const getDate=(date)=> `${date.month} ${date.year.slice(-2)}`;

  const [salesEvolutionMonthResponse, salesEvolutionMonthLoading, salesEvolutionMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/monthly-evolution/category/pnl?companyId=${company.companyId}`+
         `&category=Sales&date=${getDate(selectedDate)}`});


  useEffect(()=>{
    if (!salesEvolutionMonthLoading && (salesEvolutionMonthResponse && Object.keys(salesEvolutionMonthResponse).length > 0)) {
      setSalesEvolutionMonth(mapPnlSummaryEvolution(salesEvolutionMonthResponse,'Sales'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[salesEvolutionMonthResponse, salesEvolutionMonthLoading, salesEvolutionMonthError]);

  return(
    <div className="content-wrapper" style={{marginTop:'57px'}}>
      {
        <div className={`line-charts-wrapper`}>
          {<div className={`line-chart-container`}>
            <LineChartDoubleYAxisTest
              percentageOf={'Sales as % of sales'}
              dataValue={'Sales'}
              showGrid
              showLegend
              formatter={(value)=>formatCurrency(value,company.currency)}
              loading={salesEvolutionMonthLoading}
              error={salesEvolutionMonthError}
              data={salesEvolutionMonth}/>
          </div>}
        </div>}
    </div>
  )
}
export default TestChart;


