import UploadData from '../upload_data/UploadData';
import './CompaniesList.css';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import Loader from '../reusables/Loader';
import TokenUtil from '../util/TokenUtil'
import notify from '../notify/notify';
import withErrorBoundary from '../error/ErrorBoundary';
import Modal from 'react-modal';
import CompanyRow from './companyRow';
import {useDispatch, useSelector} from "react-redux";

async function getCompanies() {
    const headers = {
        headers: {},
        withCredentials: true
    };
    return axios
        .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/companies/xero`, headers)
        .then(response => response.data)
        .catch(error => notify({type: 'error', message: 'Something went wrong while fetching your list of companies'}));
}

function CompaniesList() {
  const  [isDeleted,setIsDeleted] =useState(false);
    const dispatch = useDispatch();
    let companyList = useSelector(state => state.companyList);
    const [isFetching, setIsFetching] = useState(false);
    const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
    const handleOpenAddCompanyModal = () => {
        setShowAddCompanyModal(true)
    }

    const handleCloseAddCompanyModal = () => {
        setShowAddCompanyModal(false)
    }

    useEffect(() => {
        async function _fetchData() {
            setIsFetching(true);
            const data = await getCompanies();
            const sortedData = data && typeof(data) !== 'object' ? data.sort((a,b)=>{
              let fa= a.companyName.toLowerCase();
              let fb = b.companyName.toLowerCase();
              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            }): data;
            data && dispatch({type:'setCompanyData',payload:sortedData});
            setIsFetching(false);
        }
        // if (isAuthorised) {
            _fetchData();
        // }
    }, [dispatch,isDeleted]);

    const deleteCallback = ()=>{
      setIsDeleted(true);
    }

    const isDemoAdded=()=>{
      const demoCompany = companyList.find((company)=>{
        return company.companyName === "Demo Company"
      });
      return !!demoCompany;
  }
    return (
        <div className="companies-container">
            <div className="companies-list-header">
                <h4>My companies</h4>
                {
                    companyList.length > 0 && !isFetching &&
                    <button className="primary-btn add-new-company-button" style={{marginLeft:'3em'}} onClick={handleOpenAddCompanyModal}>Add new
                        company
                    </button>
                }
            </div>
            <Modal
                style={{
                    content: {
                    }
                }}
                className="simpleModal"
                overlayClassName="modalOverlay"
                isOpen={showAddCompanyModal}
                contentLabel="Add a company"
                data-testid="add-company-modal"
            >
                <div className="modalHeader"><h2>Add a company</h2>
                  <button
                    className="modalCloseButton"
                    onClick={handleCloseAddCompanyModal}>
                  </button>
                </div>
                <p className="companies-prompt">Please add a company by selecting a data source from below</p>
                <UploadData callback={handleCloseAddCompanyModal} isDemoAdded={isDemoAdded()} />
            </Modal>
          {/*<p style={{margin:'0', marginTop:'2.5em',fontSize:'1.2em',color:'#080d37'}}>Choose a Company to Analyse and Prepare a Report</p>*/}
            <div className="user-intro">
                {
                    !companyList.length && !isFetching && <div>
                        {/*<p>Welcome to QoKoon, {TokenUtil.givenName()}!</p>*/}
                        <p>You are seconds away from visualizing your financial data and gaining powerful insights</p>
                        <p>To get started please select a company you wish to analyse from Xero create a Demo company.</p>
                    </div>
                }
            </div>
            {isFetching && <Loader type={'pulse'} size={20}/>}
            {
                !isFetching && companyList.length > 0  ?
                  <div className='company-list-wrapper'>
                    <p>Please choose an action for any of the companies below</p>
                    <span>(You can come back to this page by clicking the QoKoon logo at the top left of every page)</span>
                    <CompanyRow companiesList={companyList} authOrg={TokenUtil.authenticatingOrg()} deleteCallBack={deleteCallback}/>
                  </div>:
                    !isFetching && <div>
                        {/*<p className="companies-prompt">Add a company</p>*/}
                        <UploadData callback={handleCloseAddCompanyModal} isDemoAdded={isDemoAdded()}/>
                    </div>
            }
        </div>
    );
}

export default withErrorBoundary(CompaniesList);
