import { css } from "@emotion/react";

export const FooterCSS = css`
    width: 100%;
    height: 3.7em;
    bottom: 0;
    margin-top:1em;
    padding-right: 5em;
    background: #fff;
    border-top: 1px solid #ABB7C0;

    img {
        width: 32px;
        margin: 0.8em 1em 0 2em;
        float: left;
    }

    label {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.06px;
        line-height: 4em;
        color: #080D37;
    }

    .footer-link {
        float: right;
        margin: 1.2em;
        color: #305A80;
        font-size: 14px;
        font-weight: 500;
    }
`;