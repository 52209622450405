import Iframe from 'react-iframe';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
    return (
        <div className="privacy-policy" data-testid="privacy-policy">
            <h4>Privacy Policy</h4>
            <Iframe className="pdf-frame" src="../legal_docs/privacy_policy.pdf#view=FitH&toolbar=1" />
        </div>
    )
}

export default PrivacyPolicy;