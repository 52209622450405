import Iframe from 'react-iframe';
import './CookiePolicy.css';

function CookiePolicy() {
    return (
        <div className="cookie-policy" data-testid="cookie-policy">
            <h4>Cookie Policy</h4>
            <Iframe className="pdf-frame" src="../legal_docs/cookie_policy.pdf#view=FitH&toolbar=1" />
        </div>
    )
}

export default CookiePolicy;