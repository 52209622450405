import React, { useState, useEffect } from 'react';

const RandomQuotes = ({ quotes, interval }) => {
    const [renderParagraph, SetRandomParagraph] = useState(<div className="random-quote"><p>{quotes[0].text}</p><span>- {quotes[0].from}</span></div>)
    const renderQuote = (_quotes) => {
        let exists = [],
            randomNumberTemp = 0
        const max = _quotes.length;
        for (let l = 0; l < max; l++) {
            do {
                randomNumberTemp = Math.floor(Math.random() * max);
            } while (exists[randomNumberTemp]);
            exists[randomNumberTemp] = true;
        }
        SetRandomParagraph(<div className="random-quote"><p>{_quotes[randomNumberTemp].text}</p><span>- {_quotes[randomNumberTemp].from}</span></div>)
    }

    useEffect(() => {
        const intervalID = setInterval(() => renderQuote(quotes), interval || 10000);
        return () => clearInterval(intervalID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderQuote])
    return (
        <>
            {renderParagraph}
        </>
    );

}

export default RandomQuotes
