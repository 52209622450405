import React from "react";


const error412Response= (msg)=> {
  const marginErrorResponse = `EBITDA Margin can't be calculated as Sales are Zero.`;
  const dataAvailableErrorResponse = 'Data not available for the duration.';
  const notEnoughDataToCalculate = 'Not enough historic data to calculate this section.';
  if (msg === 'Insufficient data') return dataAvailableErrorResponse;
  else if (msg === 'Margin Cannot be calculated as Sales is zero') return marginErrorResponse;
  else if (msg === notEnoughDataToCalculate) return notEnoughDataToCalculate;
  else {
    console.log(msg);
    return 'Something went wrong. Try again later.';
  }
}
const ErrorResponse = ({ error,dataTestId ='error-container' }) => {
  if (error && error.response) {
    if (error.response.status === 412) {
      return (
      <div style={{ height: '100px' }} data-testid={dataTestId} className="chart-error error-icon">
        {error412Response(error.response.data)}
      </div>)
    }
    else {
      return (
        <div className="chart-error error-icon" data-testid={dataTestId}><span>Error loading data. Try again later.</span></div>
      )
    }
  }
  else {
    return (
      <div className="chart-error error-icon" data-testid={dataTestId}><span>Error loading data. Try again later.</span></div>
    )
  }
}
export default ErrorResponse;