import React, {useState} from "react";
import {Document, Page, pdfjs} from "react-pdf";
import {SizeMe} from 'react-sizeme';
import "./PdfViewer.css";
import TokenUtil from "../util/TokenUtil";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Index = ({pdf}) => {
    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    };

    const handlePdfDownload = () => {
        const link = document.createElement('a');
        link.href = pdf;
        link.download = "report.pdf";
        document.body.append(link);
        link.click();
        link.remove();
    };

    return (
        <div>
            {TokenUtil.isDevUser() && <button onClick={handlePdfDownload}>Download</button>}
            <SizeMe>
                {({size}) => (
                    <Document
                        file={pdf}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onContextMenu={(e) => e.preventDefault()}
                        className="pdf-container">
                        {Array.apply(null, Array(numPages))
                            .map((x, i) => i + 1)
                            .map(page => <Page className="pdf-page" key={page} pageNumber={page}
                                               width={size.width ? size.width : 1}/>)}
                    </Document>
                )}
            </SizeMe>

        </div>
    );
};

export default Index;
