import React from 'react';
import notify from '../notify/notify';

export default function withErrorBoundary(ProtectedComponent) {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                error: null,
                errorInfo: null
            };
        }

        componentDidCatch(error, errorInfo) {
            // Catch errors in any components below and re-render with error message
            this.setState({
                error: error,
                errorInfo: errorInfo
            });
        }

        render() {
            if (this.state.errorInfo) {
                notify({type: 'error', message: 'Sorry, we did not expect this, but something went wrong. We\'re on it already'});
                return null;
            }
            
            return <ProtectedComponent {...this.props} />
        }
    }
}