import React from "react";
import './how-to.css';

const HowTo = ()=>{
  return(
    <div>
    <div className='services'>
      <p className='hero-text' style={{fontSize:'1.3em'}}>In this page we provide you with an overview of how QoKoon is structured.</p> {/* and a glossary of terms we use in our analysis*/}
      <h3 className='hero-text' style={{fontSize:'1.5em',padding:'1em 0'}}>HOW TO</h3>
      <p className='hero-text' style={{fontSize:'1.3em'}}>QoKoon is structured in the following <strong>four sections</strong>. Navigate through these sections to quickly understand your financial performance, liquidity and business strength.</p>
    </div>
  <div className='offer-section'>
    <div className='how-to-offer'>
      <ul><p>Key Insights</p>
        <li>Quick summary about your <strong>profits and cash generation</strong></li>
        <li>We highlight <strong>potential cost saving opportunities</strong></li>
        <li>Key <strong>trend charts</strong></li>
      </ul>
    </div>
    <div className='how-to-offer'>
      <ul><p>Analyse</p>
        <h3>Drill down and visualize:</h3>
        <li>An analysis of your <strong>P&L, BS and CF</strong></li>
        <li>An analysis of your <strong>Sales, Costs, Assets, Liabilities, CF From Operations</strong>, etc...</li>
        <li>An analysis of <strong>any item of your chart of accounts</strong> (e.g. accounts receivable, salary expenses...)</li>
      </ul>
    </div>
    <div className='how-to-offer'>
      <ul><p>Anomalies</p>
        <li>Discover anomalies in your <strong>P&L, BS and CF</strong></li>
        <li><strong>Are anomalies explainable</strong> (e.g. high growth in salaries due to growing employee count)?</li>
        <li>Discover <strong>bookkeeping errors</strong></li>
        <li>Discover <strong>fraudulent activities</strong></li>
      </ul>
    </div>
    <div className='how-to-offer'>
      <ul><p>Report</p>
        <li>QoKoon prepares your <strong>monthly financial report in seconds</strong></li>
        <li><strong>Month</strong> Analysis</li>
        <li><strong>Year to Date</strong> Analysis</li>
        <li><strong>Profit Improvement</strong> Scenarios</li>
        <li><strong>Trend</strong> Analysis</li>
      </ul>
    </div>
  </div>
    </div>
  )
}
export default HowTo;
