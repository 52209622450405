import React from 'react';
import './line-chart-double-axis.css';
import getSymbolFromCurrency from 'currency-symbol-map'
import {
  Brush,
  CartesianGrid,
  Line,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Area,
  Legend
} from "recharts";
import Loader from "../reusables/Loader";
import ErrorResponse from "../error-response/error-response";

const CustomTooltip = (props) => {
  const { active, payload, label,formatter } = props;
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="tooltip-heading">{`${label}`}</p>
        <div style={{color: payload[0].stroke}}>
          <span className="tooltip-label">{payload[0].name} : </span>
          <span className="tooltip-value">{formatter(payload[0].value)}</span>
        </div>
        {payload[1] &&
          <div style={{color: payload[1].stroke}}>
            <span className="tooltip-label">{payload[1].name} : </span>
            <span className="tooltip-value">{ Math.round(payload[1].value*10)/10}%</span>
          </div>
        }
      </div>
    );
  }
  return null;
};

const CustomLegend = (props) => {
  const { payload } = props;
  if (payload && payload.length) {
    return (
      <div className="custom-legend">
        <div style={{color: payload[0].color}}>
          <span className="tooltip-label">
            {payload[0].type ==='square'?
              <span style={{background:payload[0].color}} className='legend-square'> </span>:
              <span className='legend-line'> </span>}
              {props.text.length>0 ? getSymbolFromCurrency(props.text): payload[0].value}</span>
        </div>
        {payload[1] &&
        <div style={{color: payload[1].color,marginLeft:'2em'}}>
            <span style={{background:payload[1].color}} className='legend-line'> </span>
            <span className="tooltip-label">{payload[1].value}</span>
         </div>}
      </div>
    );
  }
  return null;
};

const LineChartDoubleYAxis =({data,
                               loading,
                               error,
                               showGrid=false,
                               formatter,
                               dataValue,
                               chartHeight =340,
                               showDot=true,
                               percentageOf,
                               showBrush=true,
                               showLegend=true,
                               dataTestId='line-chart-double-y-axis',
                               tooltipTextForInsights='',
                               wrapperStyle = { fontSize: '0.8em', width: '100%',maxWidth:'700px' }})=>{
  if(loading) {
    return <div className={`test-deep`} style={{margin:'1em 0 2em 0',height:'200px'}}><Loader type={'pulse'} dataTestId={`${dataTestId}-loading`} size={10}/></div>
  }
  if(data.length > 0 && !loading) {
    return (
      <div style={wrapperStyle} data-testid={dataTestId}>
        <ResponsiveContainer height={chartHeight}>
          <ComposedChart
            height={chartHeight}
            data={data}
            margin={{top: 5, right: 10, left: 10, bottom: 5}}
          >
            {showGrid && <CartesianGrid strokeDasharray="3 3"/>}
            <XAxis dataKey="name" />
            <YAxis yAxisId="left" tickFormatter={(value)=> formatter(value)}/>
            <YAxis yAxisId="right" orientation="right" tickFormatter={(value, index)=> `${value} %`}/>
            <Tooltip content={<CustomTooltip />} formatter={(value)=> formatter(value)}/>
            {showLegend && (tooltipTextForInsights.length === 0) && <Legend wrapperStyle={{padding:'1em 0'}} />}
            {showLegend && (tooltipTextForInsights.length > 0) && <Legend content={<CustomLegend text={tooltipTextForInsights} formatter={(value)=> formatter(value)}/>} wrapperStyle={{padding:'1em 0'}} />}
            <Area yAxisId="left" type="monotone" dataKey={`${dataValue}`} activeDot={{ r: 5 }} stroke="#3571CA" fill="#3571CA" legendType={`square`} />
            {Object.keys(data[0]).length > 2 && <Line yAxisId="right" type="monotone" dataKey={percentageOf} stroke="#FE7602" dot={showDot} activeDot={{ r: 5 }} legendType={`line`} strokeWidth={3}/>}
            {showBrush && data.length > 12 && <Brush />}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    )
  }
  if(error){
    return (<ErrorResponse dataTestId={`${dataTestId}-error`} error={error}/>)
  }
  return <div> </div>
}
export default LineChartDoubleYAxis;