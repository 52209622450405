/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import withErrorBoundary from "../error/ErrorBoundary";
import { useHistory } from "react-router-dom";
import { useEffect } from 'react';
import logo from './download.gif';
import './WaitForData.css';
import RandomQuotes from "../reports/RandomQuotes";


const quotesArray = [
    {text:`Amazing how fast you were able to do the report.`,from:`"Treasurer, Listed US Company"`},
    {text:`This is amazing, I have realized a few things that I had not noticed before.`,from:`“Finance Director”`},
    {text:`Most CEO's, Board Members, etc. have a real issue understanding financial figures because they are not presented in a simple, easy format. QoKoon is very good and can solve this problem.`,from: `“Treasurer, Listed US Company”`},
    {text:`I spend a lot of time preparing the report every month and I don’t have time to do the analysis. QoKoon allows me to focus on the analysis.`,from:`“Finance Manager”`},
    {text:`I can see how it will be difficult for the finance team to hide things from the CEO as the report is done automatically by your software.`,from:`“Chairman of the Board”`},
    {text:`We have plenty of good analysis of our numbers using Qokoon.`,from:`“Finance Director”`},
    {text:`The quality of our financial reporting is thus making the other departments raise their game which makes for quality Board papers and valuable discussion.`,from: `“Finance Director”`},
    {text:`I hate having to waste 30 or 45 minutes to get the answer when a summary should give it to me in 5 minutes.`,from: `“Treasurer, Listed US Company”`}]

function WaitForData() {
    const history = useHistory();

    useEffect(() => {

        // Create WebSocket connection.
        const socket = new WebSocket(`${process.env.REACT_APP_BACKEND_WS_BASE_URL}/ping`);

        // Connection opened
        socket.addEventListener('open', function (event) {
            socket.send('Hello Server!');
        });

        // Listen for messages
        socket.addEventListener('message', function (event) {
            console.debug('Message from server ', event.data);

            if (event.data === "continue") {
                history.push('/');
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <img style={{width:'36px'}} src={logo} alt="downloading..." />
            <p style={{fontSize:'1em',lineHeight:'1.5em'}}> Please wait while we connect to your data.<br/> You will be redirected automatically in a few seconds. </p>
            <RandomQuotes quotes={quotesArray} interval={8000}/>
        </div>
    );
}


export default withErrorBoundary(WaitForData);
