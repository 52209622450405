/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Link } from 'react-router-dom';
import qokoon_symbol from '../img/qokoon-logo.png';
import {FooterCSS} from './FooterCSS';

function Footer() {
    return (
        <footer className="footer-wrapper" css={FooterCSS}>
            <img src={qokoon_symbol} id="qokoon-logo" alt="" />
            <label>Copyright 2021</label>
            <Link to="/privacypolicy" target="_blank" className="footer-link" rel="noreferrer">Privacy Policy</Link>
            <Link to="/termsandconditions" target="_blank" className="footer-link" rel="noreferrer">Terms and Conditions</Link>
        </footer>
    );
}

export default Footer;