import Axios from 'axios';

const authInterCeptor = (error) => {
  if (error.response.status === 401) {
    sessionStorage.setItem('redirectURI', window.location.hash);
    window.location.hash = '/login';
    return false;
  }
  return Promise.reject(error);
};

Axios.interceptors.response.use(response => response, authInterCeptor);


const api = Axios.create();

api.interceptors.response.use(response => response, authInterCeptor);

const get = (url, config) => Axios.get(url, { withCredentials: true, ...config });

const cachedGet = (url, config) => api.get(url, { withCredentials: true, ...config });

const post = (url, body, config) => Axios.post(url, body, { withCredentials: true, ...config });

const put = (url, body, config) => Axios.put(url, body, { withCredentials: true, ...config });

const patch = (url, body, config) => Axios.patch(url, body, { withCredentials: true, ...config });

const del = (url, config) => Axios.delete(url, { withCredentials: true, ...config });

export { get, post, put, patch, cachedGet, del };
