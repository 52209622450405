import React, {useEffect, useRef, useState} from "react";
import './intro.css';
import {Link, useHistory} from "react-router-dom";
import qokoon_title from "../img/logo.svg";
import qokoon_symbol from "../img/qokoon-logo.png";
import {useDispatch} from "react-redux";
import axios from "axios";
import notify from "../notify/notify";

const Introduction = ()=>{
  const inputRef = useRef(null);
  const [inputCode, setInputCode]= useState(null);
  const dispatch =useDispatch();
  const history =useHistory();

  async function validateAccessCode(accessCode) {
    const headers = {
      'Content-Type': 'text/plain;charset=UTF-8',
    };

    let parameters = {
      accessCode
    };

    let response = await axios(`${process.env.REACT_APP_BACKEND_BASE_URL}/access-code/validate`, {
      method: "post",
      data: parameters,
      withCredentials: true,
      headers:headers
    });
    let res = await response.data;
    if(response.status === 200 && res.valid === true){
      dispatch({type:'setIsValidUser',payload: true});
      history.push('/login');
    }
    else{
      notify({type: 'error', message: 'Oops, it seems you have entered an invalid access code. Please make sure you enter the correct one.'});
      dispatch({type:'setIsValidUser',payload: false});
    }
  }

  useEffect(()=>{
    inputRef.current.focus();
  })

  useEffect( ()=>{
    if(inputCode!==null) {
      validateAccessCode(inputCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[inputCode])

  const onSubmitClick =()=>{
    setInputCode(inputRef.current.value);
  }
  const keyPress =(e)=>{
    if(e.key === 'Enter') {
      onSubmitClick();
    }
  }

  return(
    <div className={'intro-page'}>
      <header className="header">
        <Link to="/intro"><img src={qokoon_title} id="qokoon_title" alt="qb_title" style={{marginTop:'0.3em'}}/></Link>
      </header>
      <div className='hero-container'>
        <div style={{width:'60px',height:'60px',margin:'0 auto',marginBottom:'3em'}}>
          <img src={qokoon_symbol} style={{width:'100%'}} id="qokoon-logo" alt="" />
        </div>
      <p className='hero-text'>
        <span className='highlight'>In seconds</span>,
        extract, visualize and prepare financial analysis and commentary</p>
      <p className='hero-text'>
        <span>QoKoon</span> offers you
        <span className='highlight'> insights</span>
        , detects <span className='highlight'> anomalies </span>
        and highlights
        <span className='highlight'> profit improvement opportunities</span>
      </p>
      <div className='get-started-button-container' style={{paddingTop:'2em'}}>
        <div className=''>
          <div  style={{textAlign: 'center'}}>
            <p>Please enter individual access code</p>
            <input ref={inputRef} type='text' className='input-button' onKeyPress={keyPress} placeholder={'Enter individual access code'}/>
            <button type='submit' style={{verticalAlign:'middle'}} className='primary-btn' onClick={onSubmitClick}>Submit</button>
          </div>
        </div>
        {/*<Link to="/login"><span className='primary-btn'>Get Started</span></Link>*/}
      </div>
        <hr style={{width:'90%',borderTop:'1px solid #abb7c0',height:'1px'}}/>
      </div>
      <div className='services'>
        <p className='hero-text' style={{fontSize:'1.3em'}}>If you are a Business Owner, Finance Director, Controller,
          Accountant or an Independent Consultant using <span style={{fontWeight:'500'}}>Xero</span> then try Qokoon!</p>
        <p className='hero-text' style={{fontSize:'1.3em'}}>Understand what has happened,
          why it has happened and communicate it with instant reports</p>
      </div>
        <div className='offer-section'>
        <div className='offer'>
          <ul><p>Insights</p>
            <li> Key Financial Metrics (P&L, BS, CF)</li>
            <li> Profit Variances Explained</li>
            <li>Summary of Cashflow</li>
            <li>Profit Improvement opportunities</li>
            <li>Anomalies (detect errors, fraud?)</li>
            <li>Key Financial Trends</li>
          </ul>
        </div>
          <div className='offer'>
            <ul><p>Analysis</p>
              <li> Profit and Loss</li>
              <li> Balance Sheet</li>
              <li>Cashflow</li>
              <li>Month, YTD and LTM</li>
              <li>Trends and Graphs</li>
              <li>Analysis and trends of ALL ledger accounts</li>
            </ul>
          </div>
          <div className='offer'>
            <ul><p>Reports</p>
              <li> Financial Reports (P&L, BS, CF)</li>
              <li> Detailed Automated Commentary</li>
              <li>Easy to read and understand</li>
              <li>Reports for different audiences (CFO, CEO, Board, Investors, Bankers, etc)</li>
            </ul>
          </div>
      </div>
      <div className='thank-you-text-section'>
      <p>Thank you for being our early tester and agreeing to give us your feedback!</p>
       <p>We are still learning how to do things better and your feedback will help us create a great product for you</p>
      </div>
      <div className='get-started-button-container'>
        {/*<div className=''>*/}
        {/*  <div  style={{textAlign: 'center'}}>*/}
        {/*    <p>Please enter access code</p>*/}
        {/*    <input ref={inputRef2} value={inputValue} type='text' className='input-button' onKeyPress={keyPress} placeholder={'Enter access code'}/>*/}
        {/*    <button type='submit' style={{verticalAlign:'middle'}} className='primary-btn' onClick={onSubmitClick}>Submit</button>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*<Link to="/login"><span className='primary-btn'>Get Started</span></Link>*/}
      </div>
    </div>
  )
}
export default Introduction;