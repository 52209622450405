import React from 'react';

const PieChartLabel = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx, cy, midAngle, outerRadius,name, percent,fill
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const mx = cx + (outerRadius * cos);
  const my = cy + ((outerRadius + 30) * sin);
  const ex = mx + ((cos >= 0 ? 1 : -1) * 20);
  const textAnchor = cos >= 0 ? 'start' : 'end';
   let textToShow= name;

  if(ex > 70 && ex < 100 && textToShow.length > 13){
    textToShow = textToShow.substr(0,10)+'...';
  }
  if(textToShow.length > 20){
    textToShow = textToShow.substr(0,18)+'...';
  }
  return (
    <g>
      <text x={textAnchor === 'end'? ex+10: ex-10}  y={my-5} style={{fontWeight:'500'}} textAnchor={textAnchor} fill={fill} dominantBaseline="central" fontSize="12">
      {textToShow}
    </text>
      <text x={textAnchor === 'end'? ex+10: ex-10} y={my+10} textAnchor={textAnchor} fill={"#000000"} dominantBaseline="central" fontSize="12">
        {(percent * 100).toFixed(1)}%
      </text>
    </g>
  );
};

const PieChartLabelPercent = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx, cy, midAngle, outerRadius, percent,fill
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const mx = cx + (outerRadius * cos);
  const my = cy + ((outerRadius + 30) * sin);
  const ex = mx + ((cos >= 0 ? 1 : -1) * 20);
  const textAnchor = cos >= 0 ? 'start' : 'end';
  return (
    <g>
      <text x={textAnchor === 'end'? ex+10: ex-10}  y={my-5} textAnchor={textAnchor} fill={fill} dominantBaseline="central" fontSize="12">
        {(percent * 100).toFixed(1)}%
      </text>
    </g>
  );
};

export {PieChartLabel,PieChartLabelPercent};
