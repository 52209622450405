import React, {useState, useRef} from 'react';
import './CustomSelect.css';
import useOutsideClick from "./useOutsideClick";

function CustomSelect({selectedValue, options, callback, className = '', defaultText = "Please select"}) {
    const [showList, setShowList] = useState(false);
    const ref = useRef();
    useOutsideClick(ref, () => {
        if (showList) setShowList(false);
    });

    const toggleDropDown = () => {
        showList ? setShowList(false) : setShowList(true);
    }
    const onSelect = (e) => {
        setShowList(false);
        callback(e.currentTarget.innerText);
    }

    return (
        <div className={`dropdownWrapper ${className}`} ref={ref}>
            <span data-testid="selectedValue" className={`selectedValue ${showList ? 'upArrow' : 'downArrow'}`}
                  onClick={toggleDropDown}>{selectedValue ? selectedValue : defaultText}</span>
            <ul className={`dropdownList ${showList ? 'show' : 'hide'}`}>
                {
                    showList &&
                    options.map((value, index) =>
                        value.selectable ?
                            <li
                                className={`dropdownListItem ${value.label === selectedValue ? 'selected' : ''}`}
                                key={index}
                                onClick={onSelect}>
                                {value.label}
                            </li> :
                            <li
                                className={`dropdownListItem notSelectable`}
                                key={index}>
                                {value.label}
                            </li>
                    )
                }
            </ul>
        </div>
    )
}

export default CustomSelect;
