import React from 'react';
import CustomSelect from "../custom-select";

function CustomMonthYearSelect({monthOptions, selectedYear, selectedMonth, callbackMonthChange, callBackYearChange}) {
  const years = function(currentYear) {
    let years = [],startYear = currentYear - 10;
    while ( startYear <= currentYear ) {
      years.push(`${startYear++}`);
    }

    return years.map(year => ({
      label: year,
      selectable: true
    }))
  }
  return (
    <div style={{display:'flex'}}>
          <CustomSelect selectedValue={selectedMonth} options={monthOptions} callback={callbackMonthChange}/>
          <CustomSelect selectedValue={selectedYear} options={years(new Date().getFullYear())} callback={callBackYearChange}/>
    </div>
  );
}

export default CustomMonthYearSelect;
