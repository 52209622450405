import React from "react";

const textStyle={fontSize:'1.2em',textAlign:'left', paddingTop:"1em",marginBottom:'0.5em'};
const textStylePara={fontSize:'1.2em',textAlign:'left',marginBottom:'0.5em',marginTop: '0.7em'};

const Glossary =()=>{
  return(
    <div style={{padding: '0px 1em',
      maxWidth: '1100px',
      margin: '0 auto',
      textRendering: 'optimizelegibility',
    }}>
    <h3 className='hero-text' style={{fontSize:'1.5em',padding:'1em 0'}}>GLOSSARY</h3>
      <div style={{padding:'0 1em 0 1em'}}>
        <p style={textStyle}><strong>Cashflow Generation</strong> When we talk about cash generation (as opposed to Free Cashflow), we refer
          to the actual cash generated or consumed by the company. Essentially, Cashflow generation is the
          combination of Cashflow from Operations, Cashflow from Investing and Cashflow from Financing.</p>

        <p style={textStyle}><strong>Cost of Sales (CoS)</strong> are the costs directly linked to the production of the products or services sold by
          a company. This amount includes the cost of the materials and labour directly used to create the
          good or service.</p>

        <p style={textStylePara}>Although reporting standards may require some depreciation and amortization items to be included
          within Cost of Sales, when we talk about Cost of Sales, we do not include any depreciation or
          amortization amounts, as we account for these items separately.</p>

        <p style={textStyle}><strong>EBITDA</strong> stands for Earnings Before Interest Tax Depreciation and Amortization. EBITDA is a measure
          profitability before deducting (i) costs related to past investments in fixed assets (e.g. machinery), (ii)
          interests paid to the bank and (iii) taxes.</p>

        <p style={textStylePara}>EBITDA can be considered a measure of operational profitability and it is a good indicator of whether
          your operations are profitable.</p>

        <p style={textStylePara}>QoKoon’s analysis focuses on EBITDA because by explaining EBITDA we are explaining the majority
          of the reasons for your financial performance. In addition, EBITDA is a standard measure used by
          banks and investors such as venture capital or private equity firms.</p>

        <p style={textStyle}><strong>Free Cashflow (FCF)</strong> is a measure of how much cashflow the company has generated or consumed
          from (i) operations and (ii) from investing in fixed assets (e.g. machinery).</p>

        <p style={textStylePara}>FCF also measures whether the company is generating or consuming cash before it has to borrow,
          raise cash from investors or use existing cash in the company. This is why FCF is also called <strong>Cashflow Before Financing</strong>.</p>

        {/*<p style={textStylePara}>The reason FCF is important is because, whilst a company may be generating cash, this cash may come from the bank or shareholders, which means the company is not really generating cash and the shortfall has to be contributed by the bank or the shareholders. Eventually, a company should be generating positive FCF on an ongoing basis.</p>*/}
        <p style={textStyle}><strong>LTM</strong> means Last Twelve Months and it is the period that includes the last twelve months starting
          from the current month. As an example, if we are reporting in March 2021, the LTM period is the 12
          month period starting in April 2020 and ending in March 2021.</p>

        <p style={textStylePara}>Analysing LTM figures and visualizing LTM charts is useful as it provides an idea of the performance
          for a full 12 month period without having to wait until the financial year end. As you progress
          through the year, visualizing LTM performance provides a good indication of how the performance
          of the financial year will be. </p>

        <p style={textStyle}><strong>SG&A</strong> stands for Selling, General and Administrative costs. SG&amp;A are basically your overheads, costs
          not directly related to the volume of your sales and not included in Cost of Sales.</p>

        <p style={textStylePara}>Although reporting standards may require some depreciation and amortization items to be included
          within SG&amp;A, when we talk about SG&amp;A, we do not include any depreciation or amortization
          amounts, as we account for these items separately.</p>

        <p style={textStyle}><strong>YTD</strong> means Year to Date and it is the period between the beginning of the company’s fiscal year (i.e.
          it doesn’t have to be January) and the current month. As an example, for a company whose financial
          year starts in April, when it reports in September, YTD will be 5 months.</p>

        <p style={textStylePara}>The reason FCF is important is because, whilst a company may be generating cash, this cash may
          come from the bank or shareholders, which means the company is not really generating cash and

          the shortfall has to be contributed by the bank or the shareholders. Eventually, a company should be
          generating positive FCF on an ongoing basis.</p>
      </div>
    </div>
  )
}
export default Glossary;