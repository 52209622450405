import React, { useEffect } from 'react';
import PdfViewer from '../pdf_viewer';
import excel_logo from '../img/excel_logo.png';
import axios from 'axios';
import { useState } from 'react';
import Loader from '../reusables/Loader';
import Token from '../util/TokenUtil'
import notify from '../notify/notify';
import withErrorBoundary from '../error/ErrorBoundary';
import './reports.css';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SubHeader from "../subHeader";
import { Mixpanel } from "../mixpanel";
import RandomQuotes from './RandomQuotes'

const getBlobUrl = async (response) => {
  const blob = new Blob([response.data], { type: 'application/pdf' });
  return window.URL.createObjectURL(blob);
};

async function downloadExcel({
  start_date = '2020-01-01',
  end_date = '2020-12-31',
  currency = '$',
  unit = '',
  companyId
}) {
  const headers = {};

  const params = {
    start_date,
    end_date,
    currency,
    unit,
    companyId
  };

  return axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/downloadexcel`, {
    headers,
    params,
    responseType: 'blob',
    withCredentials: true
  })
    .then(response => {
      const excelBlob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
      return window.URL.createObjectURL(excelBlob);
    })
    .catch(() => notify({ type: 'error', message: 'Could not download excel' }));
}

async function generateReport({
  start_date = '2020-01-01',
  end_date = '2020-12-31',
  currency = '£',
  unit = '',
  companyId,
  companyName
}) {
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/pdf'
  };

  const params = {
    start_date,
    end_date,
    currency,
    unit,
    companyId,
    companyName
  };
  return axios
    .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/reports`, {
      headers,
      params,
      responseType: 'blob',
      withCredentials: true
    })
    .then(response => getBlobUrl(response))
    .catch(error => {
      const errorBlob = new Blob([error.response.data], { type: 'application/pdf' });
      const reader = new FileReader();
      reader.addEventListener("loadend", function () {
        notify({ type: 'error', message: reader.result || 'Something went wrong, please try again.' });
      });
      reader.readAsText(errorBlob);
    });
}

function Reports() {
  const company = useSelector(state => state.selectedCompany);
  const history = useHistory();
  if (Object.keys(company).length === 0) {
    history.push('/home');
  }
  const endDate = useSelector(state => state.endDate);
  const startDate = useSelector(state => state.startDate);
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const startMonth = (monthNames.indexOf(startDate.month) + 1) < 10 ? `0${monthNames.indexOf(startDate.month) + 1}` : monthNames.indexOf(startDate.month) + 1;
  const startYear = startDate.year;

  const endMonth = (monthNames.indexOf(endDate.month) + 1) < 10 ? `0${monthNames.indexOf(endDate.month) + 1}` : monthNames.indexOf(endDate.month) + 1;
  const endYear = endDate.year;
  const startDateString = `${startYear}-${startMonth}-01`;

  const endDateString = `${endYear}-${endMonth}-${new Date(endYear, endMonth, 0).getDate()}`;
  const [isGeneratingReportForCompanyId, setisGeneratingReportForCompanyId] = useState('');
  const [isDownloadingExcelForCompanyId, setIsDownloadingExcelForCompanyId] = useState('');
  const [report, setReport] = useState(undefined);

  useEffect(() => {
    if (company.companyId !== isGeneratingReportForCompanyId) {
      setReport(undefined);
      setisGeneratingReportForCompanyId('');
      // setIsDownloadingExcelForCompanyId('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);
  const handleGenerateReport = async event => {
    let mounted = true;
    Mixpanel.track('Generate Report start', { company, date: startDateString });
    const companyId = event.target.dataset.companyId;
    if (!isGeneratingReportForCompanyId) {
      setisGeneratingReportForCompanyId(companyId);
      const blobUrl = await generateReport({ companyId: company.companyId, companyName: company.companyName, start_date: startDateString, end_date: endDateString });
      if (mounted) {
        setReport(blobUrl);
        Mixpanel.track('Report generated', { company, date: startDateString });
        setisGeneratingReportForCompanyId('');
      }
    }

    return () => mounted = false;
  }

  const handleDownloadExcel = async () => {
    const companyId = company.companyId;
    if (!isDownloadingExcelForCompanyId) {
      setIsDownloadingExcelForCompanyId(companyId);
      const excelFile = await downloadExcel({ companyId: company.companyId, start_date: startDateString, end_date: endDateString });
      const link = document.createElement('a');
      Mixpanel.track('Excel downloaded', { company, date: startDateString });
      link.href = excelFile;

      link.setAttribute('download', 'xero-reports.xlsx');

      document.body.appendChild(link);

      link.click();

      link.remove();
      setIsDownloadingExcelForCompanyId('');
    }
  }

  const quotesArray = [
    {text:`Amazing how fast you were able to do the report.`,from:`"Treasurer, Listed US Company"`},
    {text:`This is amazing, I have realized a few things that I had not noticed before.`,from:`“Finance Director”`},
    {text:`Most CEO's, Board Members, etc. have a real issue understanding financial figures because they are not presented in a simple, easy format. QoKoon is very good and can solve this problem.`,from: `“Treasurer, Listed US Company”`},
    {text:`I spend a lot of time preparing the report every month and I don’t have time to do the analysis. QoKoon allows me to focus on the analysis.`,from:`“Finance Manager”`},
    {text:`I can see how it will be difficult for the finance team to hide things from the CEO as the report is done automatically by your software.`,from:`“Chairman of the Board”`},
    {text:`We have plenty of good analysis of our numbers using Qokoon.`,from:`“Finance Director”`},
    {text:`The quality of our financial reporting is thus making the other departments raise their game which makes for quality Board papers and valuable discussion.`,from: `“Finance Director”`},
    {text:`I hate having to waste 30 or 45 minutes to get the answer when a summary should give it to me in 5 minutes.`,from: `“Treasurer, Listed US Company”`}]

  return (
    <div className="content-wrapper">
      <SubHeader text={'Prepare report for'} />
      <div className="reportsContainer">
        <div className="reports-list-header">
          <h5 style={{ margin: '0 auto', textAlign: 'center' }}>{company.companyName}</h5>
        </div>
        <ul data-testid="report-list" id="report-list" className="reports-list">
          <li className="report-data" key={company.companyId}>
            <button data-testid="generate-report" className="primary-btn"
              data-company-id={company.companyId} onClick={handleGenerateReport}>
              {isGeneratingReportForCompanyId === company.companyId ?
                <Loader inline={true} type={'pulse'} size={10} /> : 'Generate Report'}
            </button>
            {Token.isDevUser() && (
              <div id="download-excel" onClick={handleDownloadExcel}>
                {isDownloadingExcelForCompanyId === company.companyId ?
                  <Loader inline={true} type={'pulse'} size={10} /> :
                  <img data-testid="download-excel"
                    src={excel_logo} alt=""
                    data-company-id={company.companyId}
                    title="Download Excel"
                  />}
              </div>)
            }
          </li>
        </ul>
        <div className='report-sections'>
          <p>
            The Report has 4 Sections:
          </p>
          <div className='report-offer'>
            <li>Month Analysis</li>
            <li>Year to Date Analysis</li>
            <li>Profit Improvement Scenarios</li>
            <li>Trend Analysis</li>
          </div>
        </div>
        {isGeneratingReportForCompanyId === company.companyId &&
          <div className='loading-msg' style={{marginTop:'1em'}}>
            <Loader type={'pulse'} size={10} />
            <p style={{marginBottom:'1em'}}>It should take around 30 seconds to generate your report<br/>
             Good things come to those who wait…</p>
            <RandomQuotes quotes={quotesArray} interval={8000}/>
          </div>}
        {
          report &&
          <div id="report-frame" data-testid="report-frame">
            <PdfViewer pdf={report} />
          </div>
        }
      </div>
    </div>
  );
}

export default withErrorBoundary(Reports);
