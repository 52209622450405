import Iframe from 'react-iframe';
import './TermsAndConditions.css';

function TermsAndConditions() {
    const docName = process.env.REACT_APP_BACKEND_BASE_URL.includes('localhost') ? 'termsAndConditions-Local' : 'termsAndConditions';

    return (
        <div className="terms-and-conditions" data-testid="terms-and-conditions">
            <h4>Terms & Conditions</h4>
            <Iframe className="pdf-frame" src={`../legal_docs/${docName}.pdf#view=FitH&toolbar=1`} />
        </div>
    )
}

export default TermsAndConditions;