import './UploadData.css';
import xero_logo from '../img/xero_logo.png';
import qb_logo from '../img/qb_logo_small.png';
import excel_logo from '../img/excel_logo.png';
import TokenUtil from "../util/TokenUtil";
import withErrorBoundary from '../error/ErrorBoundary';
import axios from "axios";
import notify from "../notify/notify";
import {useDispatch} from "react-redux";
import qokoon_symbol from '../img/qokoon-logo.png';

const OAuthData = {
    xero: {
        scopes: `${process.env.REACT_APP_XERO_AUTHORISATION_SCOPES}`,
        clientID: `${process.env.REACT_APP_XERO_CLIENT_ID}`,
        redirectURI: `${process.env.REACT_APP_BACKEND_BASE_URL}/xeroredirect`
    },
    qb: {
        scopes: `${process.env.REACT_APP_QB_AUTHORISATION_SCOPES}`,
        clientID: `${process.env.REACT_APP_QB_CLIENT_ID}`,
        redirectURI: `${process.env.REACT_APP_BACKEND_BASE_URL}/qbredirect`
    }
};


function UploadData({callback,isDemoAdded}) {
    const authenticatingOrg = TokenUtil.authenticatingOrg();
    const dispatch = useDispatch();
    const state = btoa(JSON.stringify({
        authType: "authorisation"
    }));

    async function getCompanies() {
        const headers = {
            headers: {},
            withCredentials: true
        };

        return axios
          .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/companies/xero`, headers)
          .then(response => response.data)
          .catch(() => notify({type: 'error', message: 'Something went wrong while fetching your list of companies'}));
    }

    async function getDemoCompany() {
        const headers = {
            'Content-Type': 'text/plain;charset=UTF-8',
        };

        return axios(`${process.env.REACT_APP_BACKEND_BASE_URL}/dummy-company`, {
            method: "post",
            data: {name:'xero'},
            withCredentials: true,
            headers:headers
        })
          .then(response => response)
          .catch(() => notify({ type: 'error', message: 'Something went wrong while fetching your user details' }));
    }
    async function onDemoClick() {
        const res = await getDemoCompany();
        if (res && res.status === 201) {
            const data = await getCompanies();

            data && dispatch({type:'setCompanyData',payload:data});
            callback();
        }
        else{
            notify({ type: 'error', message: 'Something went wrong while creating demo company' });
            callback()
        }
    }
    const xeroOAuthLink = `${process.env.REACT_APP_XERO_HOST}/identity/connect/authorize?response_type=code&client_id=${OAuthData.xero.clientID}&redirect_uri=${OAuthData.xero.redirectURI}&scope=${OAuthData.xero.scopes}`;
    const QBOAuthLink = `https://appcenter.intuit.com/app/connect/oauth2?client_id=${OAuthData.qb.clientID}&scope=${OAuthData.qb.scopes}&redirect_uri=${OAuthData.qb.redirectURI}&response_type=code&state=${state}`;

    const hidden = 'upload-sources hidden';
    const shouldShowXeroUploadOption = authenticatingOrg === 'xero';
    const shouldShowQbUploadOption = authenticatingOrg === 'quickbooks';
    return (
        <div data-testid="upload-data" className="upload-data">
            {shouldShowXeroUploadOption &&
                <a className='upload-sources' data-testid="xero-link" href={xeroOAuthLink}><img id="xero_logo"
                                                                                                src={xero_logo}
                                                                                                alt="xero" /></a>
            }
            {shouldShowQbUploadOption &&
            <a className='upload-sources' data-testid="quickbooks-link" href={QBOAuthLink}><img id="qb_logo"
                                                                                                src={qb_logo}
                                                                                                alt="quickbooks" /></a>
            }
            { isDemoAdded ?
              <span className='upload-sources disabled' data-testid="demo-company-link" style={{flexWrap:'wrap'}}>
                  <span style={{color:'#305A80'}}>Added</span>
                    <img id='qokoon-logo' src={qokoon_symbol} alt='qokoon'/>
                    <span style={{flex:'0 0 100%',textAlign:'center',fontWeight:'bold'}}>Demo Company</span>
                </span>:
              <span className='upload-sources' data-testid="demo-company-link" style={{flexWrap:'wrap'}} onClick={onDemoClick}>

                    <img id='qokoon-logo' src={qokoon_symbol} alt='qokoon'/>
                    <span style={{flex:'0 0 100%',textAlign:'center',fontWeight:'bold'}}> Demo Company</span>
                </span>
            }
            <div className={hidden} data-testid="excel-link"><img id="excel_logo" src={excel_logo} alt="" /></div>
        </div>
    );
}

export default withErrorBoundary(UploadData);
