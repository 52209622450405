/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import CompaniesList from "../companies_list/CompaniesList";
import withErrorBoundary from "../error/ErrorBoundary";
import { HomeCSS } from './HomeCSS';
import { useState, useEffect } from 'react';
import notify from '../notify/notify';
import axios from 'axios';
import {Mixpanel} from "../mixpanel";


function Home() {
    const [, setUserDetailsFetched] = useState(false);

    async function _getUserData() {
        const data =  await getUserDetails();
        if (data) {
            const {
                authOrg,
                isDev,
                name,
                // isAuthorised
            } = data;

            isValidParam(authOrg) && localStorage.setItem("authOrg", authOrg);
            isValidParam(name) && localStorage.setItem("name", name);
            //isValidParam(isAuthorised) && localStorage.setItem("isAuthorised", isAuthorised);
            isValidParam(isDev) && localStorage.setItem("isDev", isDev);
            setUserDetailsFetched(true);
            Mixpanel.identify(name);
            Mixpanel.people.set({
                $first_name: name,
                $last_name:'',
            });
            Mixpanel.track('Successful login');
        }
    }
    useEffect(() => {
        const authOrg =localStorage.getItem('authOrg');
        const name = localStorage.getItem('name');
        if(isValidParam(authOrg) && isValidParam(name)){
            Mixpanel.track('Url Change',{name,url:'/home'});
        }
        else{
            _getUserData().then();
        }

    }, []);

    return (
        <div className="home" css={HomeCSS}>
            <CompaniesList />
        </div>
    );
}

const isValidParam = param => param !== null && param !== undefined && param !== '';

async function getUserDetails() {
    const headers = {
        headers: {},
        withCredentials: true
    };

    return axios
        .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/user-details`, headers)
        .then(response => response.data)
        .catch(error => {
            Mixpanel.track('Unsuccessful login');
            notify({ type: 'error', message: 'Something went wrong while fetching your user details' }
        )});
}



export default withErrorBoundary(Home);