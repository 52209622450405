import mixpanel from 'mixpanel-browser';

const env =process.env.REACT_APP_MIXPANEL_ENV;
let env_check = (( env === 'prod' || env === 'dev') && env !== 'local');

if(env === 'prod'){
  mixpanel.init('d7e91e8365c4377681075b68f8cb0a51');
}
else{
  mixpanel.init('09bdd04a5128d747b0d81c0c7b8b88f4');
}

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;