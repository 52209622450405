import {getUserLocales} from "get-user-locale";
import thumbsNeutral from "../img/thumbs-neutral.svg";
import thumbsUp from "../img/thumbs-up.svg";
import thumbsDown from "../img/thumbs-down.svg";
import arrow from "../img/arrow-blue.svg";
import arrowPositive from "../img/arrow-positive-up.svg";
import arrrowNegative from "../img/arrow-negative-down.svg";
import arrowNeutral from '../img/arrow-yellow-neutral.svg';

import React from "react";

const formatCurrency = (value,currency,options = {}) => {

  const currencyFormatter = new Intl.NumberFormat(getUserLocales(), {
    style: 'currency',
    currency: currency || 'GBP',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    ...options,
  });

  return currencyFormatter.format(value);
};
const mapHistoricTrend =(obj)=>{
  return obj.map((entry)=>({name: entry.date.substr(entry.date.length - 6), value: parseInt(entry.value)}))
}

const mapEvolution =(obj,paramSubCategory,paramCategory,sheet)=>{
  return obj.map((entry)=> {
    const returnObj ={name:entry.date.substr(entry.date.length - 6),
      [paramSubCategory? paramSubCategory:paramCategory]: parseInt(entry.value)};
    const percentMap = {pnl: '% of Sales',bs:'% of Total Assets'};
    if(entry.percentage !== undefined){
      returnObj[percentMap[sheet]]=entry.percentage === null? null: parseFloat(parseFloat(entry.percentage).toFixed(2))
    }
    return returnObj;
  });
}
const mapPnlSummaryEvolution =(obj,category)=> {
  if(Array.isArray(obj)) {
    return obj.map((entry) => {
      const returnObj = {
        name: entry.date.substr(entry.date.length - 6),
        [category]: parseInt(entry.value)
      };
      if (entry.percentage !== undefined) {
        returnObj[`${category} as % of sales`] = entry.percentage === null ? null : parseFloat(parseFloat(entry.percentage).toFixed(2))
      }
      return returnObj;
    });
  }
  else{
    return obj;
  }
}
const mapLargestComponentsTrends =(obj,category)=> {
  const NO_OF_MONTHS_DATA=24;
  const NO_OF_CATEGORIES=5;
  const objWithSums = obj.map((entry)=>{
    return ({name:entry.name,data:entry.data.slice(entry.data.length - NO_OF_MONTHS_DATA,entry.data.length),sum:entry.data.slice(entry.data.length - NO_OF_MONTHS_DATA,entry.data.length).reduce((acc,curr)=>{return acc+curr.value},0) });
  });
  const sortedCategories =objWithSums.sort((a,b)=> b.sum - a.sum);
  if(category === 'Equity'){
    return sortedCategories;
  }
  const topCategories = sortedCategories.slice(0,NO_OF_CATEGORIES);
  const otherCategories = sortedCategories.slice(NO_OF_CATEGORIES,sortedCategories.length);
  if(otherCategories.length){
    let flattenData = [];
    for(let i=0;i<otherCategories.length;i++){
      otherCategories[i].data.forEach((curr)=>{
        flattenData.push({date:curr.date, value: curr.value})
      })
    }
    const summedData = flattenData.reduce((acc,curr)=>{
      let key = curr['date'];
      if(acc.filter(e => e.date === key).length === 0) {
        acc.push({date:key,value:curr.value});
      }
      else{
        const objIndex = acc.findIndex((obj => obj.date === key));
        acc[objIndex].value = acc[objIndex].value + curr.value
      }
      return acc;
    },[]);
    topCategories.push({name:'Others',data: summedData, sum: sortedCategories.slice(NO_OF_CATEGORIES,sortedCategories.length).reduce((acc,curr)=> {return acc+curr.sum},0)});

  }
   return topCategories;
}
const waterfallChartMap =(obj)=>{
 if(Array.isArray(obj)) {
   return obj.reduce((acc, currValue, currIndex, arr) => {
     if (currIndex !== 0 && currIndex !== arr.length - 1) {
       acc.push({cat: currValue.cat, value: currValue.value, sum: acc[currIndex - 1].value + acc[currIndex - 1].sum});
     } else {
       acc.push({cat: currValue.cat, value: currValue.value, sum: 0});
     }
     return acc;
   }, []);
 }
 return obj;
}

const mapCategoryForDifferencesBarChart=(obj)=>{
  const ObjWithCategory= obj.map((entry)=>{
    return ({
      cat:entry.name? entry.name:entry.cat,
      value:entry.value,
    })
  })
  const noOfItems = 8;
  if(ObjWithCategory.length < 16){
    const positiveDiffs = ObjWithCategory.filter((entry)=> entry.value >= 0).sort((a,b)=> b.value - a.value);
    const negativeDiffs= ObjWithCategory.filter((entry)=> entry.value < 0).sort((a,b)=> a.value - b.value);
    return [...positiveDiffs,...negativeDiffs.reverse()];
  }
  const positiveDiffs = ObjWithCategory.filter((entry)=> entry.value > 0).sort((a,b)=> b.value - a.value);
  const negativeDiffs= ObjWithCategory.filter((entry)=> entry.value < 0).sort((a,b)=> a.value - b.value);
  const topPositive = positiveDiffs.slice(0,noOfItems);
  const othersPositive = positiveDiffs.slice(noOfItems,positiveDiffs.length);
  const othersPositiveSum = othersPositive.reduce((acc,curr)=>{return acc+curr.value},0);
  const topNegative = negativeDiffs.slice(0,noOfItems);
  const otherNegative = negativeDiffs.slice(noOfItems,negativeDiffs.length);
  const othersNegativeSum = otherNegative.reduce((acc,curr)=>{return acc+curr.value},0);
  othersPositiveSum > 0 && topPositive.push({'cat':'All Other increasing',value:othersPositiveSum});
  othersNegativeSum !== 0 && topNegative.push({'cat':'All Other decreasing',value:othersNegativeSum});
  return [...topPositive,...topNegative.reverse()];
}

const mapCategoryForPieChart =(obj)=>{
  const NO_OF_CATEGORIES =5;
  const sortedContribution= obj.contribution? obj.contribution.sort((a,b)=>b.value - a.value): [];
  const majorContributions = sortedContribution.slice(0,NO_OF_CATEGORIES);
  let nonZeroMajorContributions=[];
  majorContributions.forEach((item)=> {if(item.value > 0) nonZeroMajorContributions.push(item)});
  nonZeroMajorContributions.sort(function(a, b){return b-a});
  const minorContributions = sortedContribution.slice(NO_OF_CATEGORIES,sortedContribution.length);
  minorContributions.length && nonZeroMajorContributions.push({name:'Others',value: minorContributions.reduce((acc,curr)=> {return acc+ curr.value},0)})
  return ({absolute: obj.absolute,contribution: nonZeroMajorContributions,label:obj.label});
}

const mapLTMPrevChartData= (obj)=> {
  const currentYearArray = obj.currentYear.map((entry)=> ({'Month': entry.date.length > 6? entry.date.substr(entry.date.length - 6): entry.date.substr(0,3),'Current Year':parseInt(entry.value)}));
  const previousYearArray = obj.previousYear.map((entry)=> ({'Month': entry.date.length > 6? entry.date.substr(entry.date.length - 6): entry.date.substr(0,3),'Previous Year':parseInt(entry.value)}));
  function mergeArrayObjects(currentYearArray,previousYearArray){
    return currentYearArray.map((item,i)=>{
      if(item.month === previousYearArray[i].month){
        return Object.assign({},item,previousYearArray[i])
      }
      return null
    })
  }
  return mergeArrayObjects(currentYearArray,previousYearArray);
}

const mapLTMPrevBarChartData= (obj)=> {
  const currentYearArray = obj.currentYear.map((entry)=> ({'Month': entry.date.length > 6? entry.date.substr(entry.date.length - 6): entry.date,'Current Year':parseInt(entry.value)}));
  const previousYearArray = obj.previousYear.map((entry)=> ({'Month': entry.date.length > 6? entry.date.substr(entry.date.length - 6): entry.date,'Previous Year':parseInt(entry.value)}));
  function mergeArrayObjects(currentYearArray,previousYearArray){
    return currentYearArray.map((item,i)=>{
      if(item.month === previousYearArray[i].month){
        return Object.assign({},item,previousYearArray[i])
      }
      return null
    })
  }
  return mergeArrayObjects(currentYearArray,previousYearArray);
}
const showWriteupBS=(writeup,key='bs-writeup')=>{
  const growthTrendVsPrevYear=[];
  const recentGrowthTrend=[];
  const monthWriteUp=[];
  const LTMWriteUp=[];
  const YTDWriteUp=[];
  const prev3month=[];
  const prev6month=[];
  const prev9month=[];

  const signMapBSCss ={
    '0':'arrowBSNeutral',
    '1':'arrowBSUp',
    '-1':'arrowBSDown'
  }
  const signImageBsSubcategory= {
    '0':arrow,
    '1':arrow,
    '-1':arrow,
  }

  const analysisWriteupHTML =(property,signSummary,signImpact)=>{
    const pnlAnalysis =writeup[property].pnl_analysis;
    return (
      <div key={property}>
        <p className={`writeup-heading`}>{pnlAnalysis.title}</p>
        {(pnlAnalysis.summary_text && pnlAnalysis.summary_text.length > 0) &&
         <p className={`writeup-text`}>
           <img className={signMapBSCss[signSummary.toString()]} alt={signSummary}
                src={signImageBsSubcategory[signSummary.toString()]}/>
           {pnlAnalysis.summary_text}
         </p>
        }
        { (pnlAnalysis.comparison_text || pnlAnalysis.impact_text) && <div>
          {(pnlAnalysis.comparison_text.length > 0 || pnlAnalysis.impact_text.length > 0) &&
           <p className={`writeup-text`}>
             <img className={signMapBSCss[signImpact.toString()]} alt={signImpact}
                  src={signImageBsSubcategory[signImpact.toString()]}/>
             {pnlAnalysis.impact_text}&nbsp;
             {pnlAnalysis.comparison_text}</p>
          }</div>
        }
      </div>
    )
  }
  const allWriteupText =[];
  for (const property in writeup) {
    const signSummary = writeup[property].pnl_analysis.summary_arrow;
    const signImpact = writeup[property].pnl_analysis.impact_arrow;

    if(property === 'LTM' || property === "This Month vs Previous Year"){
      monthWriteUp.push(analysisWriteupHTML(property,signSummary,signImpact))
    }
    if(property === 'Month' || property === "This Month vs Previous Month"){
      YTDWriteUp.push(analysisWriteupHTML(property,signSummary,signImpact))
    }
    if(property === 'YTD' || property === "This Month vs Last Financial Year End"){
      LTMWriteUp.push(analysisWriteupHTML(property,signSummary,signImpact));
    }
    if(property === 'Last 3 Months vs Previous 3 Months'){
      prev3month.push(analysisWriteupHTML(property,signSummary,signImpact))
    }
    if(property === 'Last 3 Months vs Previous 6 Months'){
      prev6month.push(analysisWriteupHTML(property,signSummary,signImpact))
    }
    if(property === 'Last 3 Months vs Previous 9 Months'){
      prev9month.push(analysisWriteupHTML(property,signSummary,signImpact))
    }
  }
  growthTrendVsPrevYear.push(<div className={`writeup-wrapper`}>{YTDWriteUp}{LTMWriteUp}{monthWriteUp}</div>);
  recentGrowthTrend.push(<div className={`writeup-wrapper`}>{prev3month}{prev6month}{prev9month}</div>);
  allWriteupText.push(<div key={key} className={`writeups-container`}>{growthTrendVsPrevYear}{recentGrowthTrend}</div>);
  return allWriteupText;
}
const showWriteupPnL=(writeup,paramCategory,key)=>{
  const growthTrendVsPrevYear=[];
  const recentGrowthTrend=[];
  const monthWriteUp=[];
  const LTMWriteUp=[];
  const YTDWriteUp=[];
  const prev3month=[];
  const prev6month=[];
  const prev9month=[];
  const signMapCss ={
    '0':'thumbsNeutral',
    '1':'thumbsUp',
    '-1':'thumbsDown'
  }

  const signMapArrowSalesCss ={
    '0':'arrowPnlNeutralSales',
    '1':'arrowPnlUpSales',
    '-1':'arrowPnlDownSales'
  }

  const signMapArrowCosCss ={
    '0':'arrowPnlNeutralCos',
    '1':'arrowPnlUpCos',
    '-1':'arrowPnlDownCos'
  }
  const signImage= {
    '0':thumbsNeutral,
    '1':thumbsUp,
    '-1':thumbsDown,
  }
  const signImageArrow= {
    '0':arrowNeutral,
    '1':arrowPositive,
    '-1':arrrowNegative,
  }
  const signImageArrowCos= {
    '0':arrowNeutral,
    '-1':arrowPositive,
    '1':arrrowNegative,
  }

  const analysisWriteupHTML =(property,signSummary,signImpact)=>{
    const pnlAnalysis =writeup[property].pnl_analysis;
    return (
      <div key={property}>
        <p className={`writeup-heading`}>{pnlAnalysis.title}</p>
        {(pnlAnalysis.summary_text && pnlAnalysis.summary_text.length > 0) &&
         <p className={`writeup-text`}>
           <img className={paramCategory==='Sales'? signMapArrowSalesCss[signSummary.toString()]:signMapArrowCosCss[signSummary.toString()]} alt={signSummary}
                src={paramCategory==='Sales'? signImageArrow[signSummary.toString()]:signImageArrowCos[signSummary.toString()] }/>
           {pnlAnalysis.summary_text}
         </p>
        }
        { (pnlAnalysis.comparison_text || pnlAnalysis.impact_text) && <div>
          {(pnlAnalysis.comparison_text.length > 0 || pnlAnalysis.impact_text.length > 0) &&
           <p className={`writeup-text`}>
             <img className={signMapCss[signImpact.toString()]} alt={signImpact}
                  src={signImage[signImpact.toString()]}/>
             {pnlAnalysis.impact_text}&nbsp;
             {pnlAnalysis.comparison_text}</p>
          }</div>
        }
      </div>
    )
  }
  const allWriteupText =[];
  for (const property in writeup) {
    const signSummary = writeup[property].pnl_analysis.summary_arrow;
    const signImpact = writeup[property].pnl_analysis.impact_arrow;
    if(property === 'LTM' || property === "This Month vs Previous Year"){
      monthWriteUp.push(analysisWriteupHTML(property,signSummary,signImpact))
    }
    if(property === 'Month' || property === "This Month vs Previous Month"){
      YTDWriteUp.push(analysisWriteupHTML(property,signSummary,signImpact))
    }
    if(property === 'YTD' || property === "This Month vs Last Financial Year End"){
      LTMWriteUp.push(analysisWriteupHTML(property,signSummary,signImpact));
    }
    if(property === 'Last 3 Months vs Previous 3 Months'){
      prev3month.push(analysisWriteupHTML(property,signSummary,signImpact))
    }
    if(property === 'Last 3 Months vs Previous 6 Months'){
      prev6month.push(analysisWriteupHTML(property,signSummary,signImpact))
    }
    if(property === 'Last 3 Months vs Previous 9 Months'){
      prev9month.push(analysisWriteupHTML(property,signSummary,signImpact))
    }
  }
  growthTrendVsPrevYear.push(<div className={`writeup-wrapper`}>{YTDWriteUp}{LTMWriteUp}{monthWriteUp}</div>);
  recentGrowthTrend.push(<div className={`writeup-wrapper`}>{prev3month}{prev6month}{prev9month}</div>);
  allWriteupText.push(<div key={key} className={`writeups-container`}>{growthTrendVsPrevYear}{recentGrowthTrend}</div>);
  return allWriteupText;
}

export {formatCurrency,
  mapCategoryForDifferencesBarChart,
  mapHistoricTrend,
  mapEvolution,
  mapLTMPrevChartData,
  mapCategoryForPieChart,
  mapLargestComponentsTrends,
  waterfallChartMap,
  mapLTMPrevBarChartData,
  mapPnlSummaryEvolution,
  showWriteupBS,
  showWriteupPnL,
};
