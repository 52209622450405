import React, {useEffect, useState} from "react";
import SubHeader from "../subHeader";
import LineChartDoubleYAxis from "../line-chart-double-y-axis";
import {formatCurrency, mapEvolution, mapPnlSummaryEvolution, waterfallChartMap} from "../analysis-helper";
import useGet from "../hooks/use-get";
import {useSelector} from "react-redux";
import WaterfallChart from "../waterfall-chart";
import BarChartOneCategory from "../bar-chart-one-category";
import './key-insights.css';
import Loader from "../reusables/Loader";
import arrowUp from '../img/arrow-positive-up.svg';
import arrowDown from '../img/arrow-negative-down.svg';
import arrowNeutral from '../img/arrow-right-neutral.svg';
import thumbsUp from '../img/thumbs-up.svg';
import thumbsDown from '../img/thumbs-down.svg';
import thumbsNeutral from '../img/thumbs-neutral.svg';
import SimpleBarChart from "../simple-bar-chart";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";
import httpClient from '../util/index';
import ErrorResponse from "../error-response/error-response";

const KeyInsights =()=>{
  const company = useSelector(state => state.selectedCompany);
  const selectedDate = useSelector(state => state.endDate);
  const leftNavigation = useSelector(state => state.leftNavigation);
  const history = useHistory();
  if(!leftNavigation){
    history.replace('/login');
  }
  const [salesEvolutionMonth,setSalesEvolutionMonth]=useState({});
  const [overallEbitdaEvolutionMonth,setOverallEbitdaEvolutionMonth]=useState([]);
  const [netIncomeEvolutionMonth,setNetIncomeEvolutionMonth]=useState({});

  const [categoryCfGenerationMonthlyChart,setCategoryCfGenerationMonthlyChart]=useState({});
  const [categoryCfGenerationYTDChart,setCategoryCfGenerationYTDChart]=useState({});
  const [lTMPrevChart,setLTMPrevChart]=useState([]);

  const [cashEquivalentsChart,setCashEquivalentsChart]=useState([]);
  const [keyInsights,setKeyInsights]=useState([]);
  const [cfGenerationChartTwelveMonths,setCfGenerationChartTwelveMonths]=useState({});

  const [overallEbitdaContriWriteup,setOverallEbitdaContriWriteup]=useState([]);

  const [costSavingOpportunities,setCostSavingOpportunities]=useState({});

  const [costSavingOpportunitiesCat1,setCostSavingOpportunitiesCat1]=useState({});
  const [costSavingOpportunitiesCat2,setCostSavingOpportunitiesCat2]=useState({});
  const [costSavingOpportunitiesCat3,setCostSavingOpportunitiesCat3]=useState({});
  const [costSavingOpportunitiesCat4,setCostSavingOpportunitiesCat4]=useState({});
  const [costSavingOpportunitiesCat5,setCostSavingOpportunitiesCat5]=useState({});

  const getDate=(date)=> `${date.month} ${date.year.slice(-2)}`;

  const [overallEbitdaContriWriteupResponse, overallEbitdaContriWriteupLoading, overallEbitdaContriWriteupError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/writeup/overall/pnl?companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}`});


  const [keyInsightsResponse, keyInsightsLoading, keyInsightsError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/insights/snapshots?companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}`});

  const [cashEquivalentsChartResponse, cashEquivalentsChartLoading, cashEquivalentsChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/historic-data/special-identifier?companyId=${company.companyId}`+
         `&identifier=CASH&date=${getDate(selectedDate)}&identifier=Cash&sheet=BS`});

  const [salesEvolutionMonthResponse, salesEvolutionMonthLoading, salesEvolutionMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/monthly-evolution/category/pnl?companyId=${company.companyId}`+
         `&category=Sales&date=${getDate(selectedDate)}`});

  const [overallEbitdaEvolutionMonthResponse, overallEbitdaEvolutionMonthLoading, overallEbitdaEvolutionMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/monthly-evolution/derived-category/pnl?companyId=${company.companyId}`+
         `&category=EBITDA`+
         `&date=${getDate(selectedDate)}`});

  const [netIncomeEvolutionMonthResponse, netIncomeEvolutionMonthLoading, netIncomeEvolutionMonthError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/monthly-evolution/derived-category/pnl?companyId=${company.companyId}`+
         `&category=Net%20Income&date=${getDate(selectedDate)}`});

  const [categoryCfGenerationMonthlyResponse, categoryCfGenerationMonthlyLoading, categoryCfGenerationMonthlyError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/cfGeneration?companyId=${company.companyId}`+
         `&category=CF%20Summary`+
         `&date=${getDate(selectedDate)}`+
         `&breakdown=MONTH`});

  const [categoryCfGenerationYTDResponse, categoryCfGenerationYTDLoading, categoryCfGenerationYTDError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/cfGeneration?companyId=${company.companyId}`+
         `&category=CF%20Summary`+
         `&date=${getDate(selectedDate)}`+
         `&breakdown=YTD`});

  const [lTMPrevChartResponse, lTMPrevChartLoading, lTMPrevChartError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/ltm-vs-previous/category?`+
         `companyId=${company.companyId}`+
         `&category=CF%20From%20Operations`+
         `&date=${getDate(selectedDate)}&sheet=CF`
  });

  const [cfGenerationChartTwelveMonthsResponse, cfGenerationChartTwelveMonthsLoading, cfGenerationChartTwelveMonthsError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/cfgeneration/ltm?companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}`
  });

  const [costSavingOpportunitiesResponse, costSavingOpportunitiesLoading, costSavingOpportunitiesError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/cost-saving-opportunities?companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}`});

  const [
    { costSavingOpportunitiesCat1Response,
      costSavingOpportunitiesCat1Loading,
      costSavingOpportunitiesCat1Error 
    }, setter1] = useState(() => {
      return { costSavingOpportunitiesCat1Response: [], costSavingOpportunitiesCat1Error: null, costSavingOpportunitiesCat1Loading: false }
    });

  const [
    { costSavingOpportunitiesCat2Response,
      costSavingOpportunitiesCat2Loading,
      costSavingOpportunitiesCat2Error
    }, setter2] = useState(() => {
      return { costSavingOpportunitiesCat2Response: [], costSavingOpportunitiesCat2Error: null, costSavingOpportunitiesCat2Loading: false }
    });

  const [
    { costSavingOpportunitiesCat3Response,
      costSavingOpportunitiesCat3Loading,
      costSavingOpportunitiesCat3Error
    }, setter3] = useState(() => {
      return { costSavingOpportunitiesCat3Response: [], costSavingOpportunitiesCat3Error: null, costSavingOpportunitiesCat3Loading: false }
    });

  const [
    { costSavingOpportunitiesCat4Response,
      costSavingOpportunitiesCat4Loading,
      costSavingOpportunitiesCat4Error
    }, setter4] = useState(() => {
      return { costSavingOpportunitiesCat4Response: [], costSavingOpportunitiesCat4Error: null, costSavingOpportunitiesCat4Loading: false }
    });

  const [
    { costSavingOpportunitiesCat5Response,
      costSavingOpportunitiesCat5Loading,
      costSavingOpportunitiesCat5Error
    }, setter5] = useState(() => {
      return { costSavingOpportunitiesCat5Response: [], costSavingOpportunitiesCat5Error: null, costSavingOpportunitiesCat5Loading: false }
    });

  const mapCfGenerationHistoricTrend =(obj)=>{
    return Object.keys(obj).length> 0 ? obj.map((entry)=>({date: entry.date.substr(entry.date.length - 6), value: parseInt(entry.value)})): obj
  }

  useEffect(() => {
    const updateState = [
      (params) => {
        setter1(prevState => ({
          ...prevState, ...{
            costSavingOpportunitiesCat1Response: params.response,
            costSavingOpportunitiesCat1Loading: params.loading,
            costSavingOpportunitiesCat1Error: params.error
          }
        }));
      },
      (params) => {
        setter2(prevState => ({
          ...prevState, ...{
            costSavingOpportunitiesCat2Response: params.response,
            costSavingOpportunitiesCat2Loading: params.loading,
            costSavingOpportunitiesCat2Error: params.error
          }
        }));
      },
      (params) => {
        setter3(prevState => ({
          ...prevState, ...{
            costSavingOpportunitiesCat3Response: params.response,
            costSavingOpportunitiesCat3Loading: params.loading,
            costSavingOpportunitiesCat3Error: params.error
          }
        }));
      },
      (params) => {
        setter4(prevState => ({
          ...prevState, ...{
            costSavingOpportunitiesCat4Response: params.response,
            costSavingOpportunitiesCat4Loading: params.loading,
            costSavingOpportunitiesCat4Error: params.error
          }
        }));
      },
      (params) => {
        setter5(prevState => ({
          ...prevState, ...{
            costSavingOpportunitiesCat5Response: params.response,
            costSavingOpportunitiesCat5Loading: params.loading,
            costSavingOpportunitiesCat5Error: params.error
          }
        }));
      }
    ];

    const getCategoryName=(index)=>{
      let categoryName='';
      leftNavigation.response['Profit and Loss'].forEach((category)=>{
          if(category.name === 'Sales' || category.name === 'Cost of Sales' || category.name === 'SG&A'){
            if(category.subcategories.includes(costSavingOpportunities.categories[index])){
              categoryName = category.name;
            }
          }
        });
      return categoryName.replaceAll('&','%26');
    }

    const initialState = () => {
      return { error: null, loading: false, response: []};
    }

    const fetchData = async (opportunities, index) => {
      //console.log("called for: " + index)

      var params = initialState();
      var subCategory = opportunities.categories[index] && opportunities.categories[index].replaceAll('&', '%26');
      var category = getCategoryName(index);

      if (category === '') {
        updateState[index]({ ...params, error: true});
        return
      }

      try {
        const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/monthly-evolution/pnl?companyId=${company.companyId}` +
          `&subCategory=${subCategory}&category=${category}&date=${getDate(selectedDate)}`;

        const result = await httpClient.get(url);

        params = { ...params, response: result.data, error: null, loading: false };
      } catch (err) {
        params = { ...params, error: err, loading: false };
      }

      //console.log({ params });

      updateState[index](params);
    }

    if (costSavingOpportunities && costSavingOpportunities.categories) {
      costSavingOpportunities.categories.forEach((e, i) => {
        fetchData(costSavingOpportunities, i);
      });
      var index = costSavingOpportunities.categories.length;
      while (index < 5) {
        updateState[index](initialState());
        index++;
      }
    }

  }, [costSavingOpportunities, company.companyId, selectedDate, leftNavigation.response]);

  useEffect(()=>{
      if (!overallEbitdaContriWriteupLoading && overallEbitdaContriWriteupResponse) {
        setOverallEbitdaContriWriteup(overallEbitdaContriWriteupResponse);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[overallEbitdaContriWriteupResponse,
    overallEbitdaContriWriteupLoading,
    overallEbitdaContriWriteupError,
    company
   ]);

  useEffect(() => {
    if (!costSavingOpportunitiesCat1Loading && costSavingOpportunities.categories) {
      if (costSavingOpportunitiesCat1Response.length === 0 || costSavingOpportunities.categories.length < 1) {
        setCostSavingOpportunitiesCat1([]);
      } else {
        setCostSavingOpportunitiesCat1(
          mapEvolution(
            costSavingOpportunitiesCat1Response.slice(-18),
            costSavingOpportunities?.categories[0],
            'SG&A',
            'pnl'));
      }


    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costSavingOpportunities,
    costSavingOpportunitiesCat1Response,
    costSavingOpportunitiesCat1Loading,
    costSavingOpportunitiesCat1Error,
    company
  ]);
  
  useEffect(() => {
    if (!costSavingOpportunitiesCat2Loading && costSavingOpportunities.categories) {

      if (costSavingOpportunitiesCat2Response.length === 0 || costSavingOpportunities.categories.length < 2) {
        setCostSavingOpportunitiesCat2([]);
      } else {
        setCostSavingOpportunitiesCat2(
          mapEvolution(
            costSavingOpportunitiesCat2Response.slice(-18),
            costSavingOpportunities?.categories[1],
            'SG&A',
            'pnl'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costSavingOpportunities, costSavingOpportunitiesCat2Response, costSavingOpportunitiesCat2Loading, costSavingOpportunitiesCat2Error
  ]);

  useEffect(() => {
    if (!costSavingOpportunitiesCat3Loading && costSavingOpportunities.categories) {

      if (costSavingOpportunitiesCat3Response.length === 0 || costSavingOpportunities.categories.length < 3) {
        setCostSavingOpportunitiesCat3([]);
      } else {
        setCostSavingOpportunitiesCat3(
          mapEvolution(
            costSavingOpportunitiesCat3Response.slice(-18),
            costSavingOpportunities?.categories[2],
            'SG&A',
            'pnl'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costSavingOpportunities, costSavingOpportunitiesCat3Response, costSavingOpportunitiesCat3Loading, costSavingOpportunitiesCat3Error
  ]);

  useEffect(() => {
    if (!costSavingOpportunitiesCat4Loading && costSavingOpportunities.categories) {

      if (costSavingOpportunitiesCat4Response.length === 0 || costSavingOpportunities.categories.length < 4) {
        setCostSavingOpportunitiesCat4([]);
      } else {

        setCostSavingOpportunitiesCat4(
          mapEvolution(
            costSavingOpportunitiesCat4Response.slice(-18),
            costSavingOpportunities?.categories[3],
            'SG&A',
            'pnl'));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costSavingOpportunities, costSavingOpportunitiesCat4Response, costSavingOpportunitiesCat4Loading, costSavingOpportunitiesCat4Error
  ]);

  useEffect(() => {
    if (!costSavingOpportunitiesCat5Loading && costSavingOpportunities.categories) {

      if (costSavingOpportunitiesCat5Response.length === 0 || costSavingOpportunities.categories.length < 5) {
        setCostSavingOpportunitiesCat5([]);
      } else {
        setCostSavingOpportunitiesCat5(
          mapEvolution(
            costSavingOpportunitiesCat5Response.slice(-18),
            costSavingOpportunities?.categories[4],
            'SG&A',
            'pnl'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costSavingOpportunities, costSavingOpportunitiesCat5Response, costSavingOpportunitiesCat5Loading, costSavingOpportunitiesCat5Error
  ]);
  useEffect(()=>{
      if (!costSavingOpportunitiesLoading && (costSavingOpportunitiesResponse  && Object.keys(costSavingOpportunitiesResponse).length > 0)) {
        setCostSavingOpportunities(costSavingOpportunitiesResponse);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return ()=>{
        setCostSavingOpportunities({});
        setCostSavingOpportunitiesCat1({});
      setCostSavingOpportunitiesCat2({});
      setCostSavingOpportunitiesCat3({});
      setCostSavingOpportunitiesCat4({});
      setCostSavingOpportunitiesCat5({});
      }
  },[costSavingOpportunitiesResponse,
    costSavingOpportunitiesLoading,
    costSavingOpportunitiesError,
   ]);
  useEffect(()=>{
      if (!cfGenerationChartTwelveMonthsLoading && (cfGenerationChartTwelveMonthsResponse && Object.keys(cfGenerationChartTwelveMonthsResponse).length > 0)) {
        setCfGenerationChartTwelveMonths(mapCfGenerationHistoricTrend(cfGenerationChartTwelveMonthsResponse));
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cfGenerationChartTwelveMonthsResponse,
    cfGenerationChartTwelveMonthsLoading,
    cfGenerationChartTwelveMonthsError,
    ]);
  useEffect(()=>{
      if (!salesEvolutionMonthLoading && (salesEvolutionMonthResponse && Object.keys(salesEvolutionMonthResponse).length > 0)) {
        setSalesEvolutionMonth(mapPnlSummaryEvolution(salesEvolutionMonthResponse,'Sales'));
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[salesEvolutionMonthResponse, salesEvolutionMonthLoading, salesEvolutionMonthError]);

  useEffect(()=>{
    if (!keyInsightsLoading && (keyInsightsResponse && Object.keys(keyInsightsResponse).length > 0)) {
      setKeyInsights(keyInsightsResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[keyInsightsResponse, keyInsightsLoading, keyInsightsError]);

  useEffect(()=>{
      if (!cashEquivalentsChartLoading && (cashEquivalentsChartResponse && Object.keys(cashEquivalentsChartResponse).length > 0)) {
        setCashEquivalentsChart(mapPnlSummaryEvolution(cashEquivalentsChartResponse,'Cash'));
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cashEquivalentsChartResponse, cashEquivalentsChartLoading, cashEquivalentsChartError])

  useEffect(()=>{
      if (!overallEbitdaEvolutionMonthLoading && (overallEbitdaEvolutionMonthResponse && Object.keys(overallEbitdaEvolutionMonthResponse).length > 0)) {
        setOverallEbitdaEvolutionMonth(mapPnlSummaryEvolution(overallEbitdaEvolutionMonthResponse,'EBITDA'));
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    overallEbitdaEvolutionMonthResponse,
    overallEbitdaEvolutionMonthLoading,
    overallEbitdaEvolutionMonthError,])

  useEffect(()=>{
      if (!netIncomeEvolutionMonthLoading && (netIncomeEvolutionMonthResponse && Object.keys(netIncomeEvolutionMonthResponse).length > 0)) {
        setNetIncomeEvolutionMonth(mapPnlSummaryEvolution(netIncomeEvolutionMonthResponse,'Net Income'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    netIncomeEvolutionMonthResponse,
    netIncomeEvolutionMonthLoading,
    netIncomeEvolutionMonthError,
  ])

  useEffect(()=>{
      if (!categoryCfGenerationMonthlyLoading && (categoryCfGenerationMonthlyResponse && Object.keys(categoryCfGenerationMonthlyResponse).length > 0)) {
        setCategoryCfGenerationMonthlyChart(waterfallChartMap(categoryCfGenerationMonthlyResponse));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    categoryCfGenerationMonthlyLoading,
    categoryCfGenerationMonthlyResponse,
    categoryCfGenerationMonthlyError
  ]);

  useEffect(()=>{
      if (!categoryCfGenerationYTDLoading && (categoryCfGenerationYTDResponse && Object.keys(categoryCfGenerationYTDResponse).length > 0)) {
        setCategoryCfGenerationYTDChart(waterfallChartMap(categoryCfGenerationYTDResponse));
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    categoryCfGenerationYTDLoading,
    categoryCfGenerationYTDResponse,
    categoryCfGenerationYTDError
  ]);

  useEffect(()=>{
      if (!lTMPrevChartLoading && (lTMPrevChartResponse && Object.keys(lTMPrevChartResponse).length > 0)) {
        setLTMPrevChart(lTMPrevChartResponse);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[lTMPrevChartResponse,
    lTMPrevChartLoading,
    lTMPrevChartError
  ])

  const showArrow=(value)=>{
     if(value > 0) {
     return arrowUp;
     }
     else if(value < 0){
       return arrowDown;
     }
     else{
       return arrowNeutral;
     }
  }
  const showArrowClass=(value)=>{
    if(value > 0) {
      return 'arrow-positive-up';
    }
    else if(value < 0){
      return 'arrow-negative-down';
    }
    else{
      return 'arrow-neutral';
    }
  }


  const showThumb =(value)=>{
     if(value > 0) {
     return thumbsUp;
     }
     else if(value < 0){
       return thumbsDown;
     }
     else{
       return thumbsNeutral;
     }
  }

  return(
    <div className="content-wrapper" style={{marginTop:'57px'}}>
      <SubHeader text={'For'} heading={''}/>
      <h5 className='page-title'>Key Insights</h5>
      <div style={{marginTop:'85px'}}>
        <h3 style={{fontSize:'1.2em',
          textAlign:'center',
          padding:'0.5em 0 0.5em 0',
          color:'rgb(57, 80, 94)'}}>Snapshot of Your Profits and Your Cash</h3>
      </div>
      <div>
      {keyInsightsLoading && <Loader type={'pulse'} size={10}/>}
      { !keyInsightsLoading && !keyInsightsError &&
      <div className='key-insights-container' style={{display:'flex',flexWrap:'wrap'}}>
        <div className='key-insight'>
          <label>Month EBITDA</label>
           <span>{keyInsights.ebitda && <span style={{color:keyInsights.ebitda.month.value >= 0? '#090909':'red',padding:'0'}}>
             {formatCurrency(keyInsights.ebitda.month.value,company.currency)}</span>}
           </span>
          <div style={{paddingTop:'0.5em'}}>
            {keyInsights.ebitda && <img className={showArrowClass(keyInsights.ebitda.month.difference)}
                 src={showArrow(keyInsights.ebitda.month.difference)}
                 alt={keyInsights.ebitda.month.difference >= 0? 'Up':'Down'}/>}
            <span>{keyInsights.ebitda &&
                     <span style={{color:keyInsights.ebitda.month.difference >= 0? '#090909':'red',padding:'0'}}>
                      {formatCurrency(keyInsights.ebitda.month.difference,company.currency)}
                      &nbsp;<span>({parseFloat(keyInsights.ebitda.month.percentage).toFixed(1)}%)</span></span>
                   }
            </span>
          </div>
         </div>
        <div className='key-insight'>
          <label>Month Free Cashflow</label>
          <span style={{
              display:'block',
              padding:'0',
              marginTop:'0.5em'}}>
            {keyInsights.fcf && <img className='thumbs-positive-negative'
                                        src={showThumb(keyInsights.fcf.month)}
                                        alt={keyInsights.fcf.month >= 0? 'Up':'Down'}/>}
            {keyInsights.fcf &&
             <span style={{color:keyInsights.fcf.month >= 0? '#090909':'red',padding:'0'}}>
               {formatCurrency(keyInsights.fcf.month,company.currency)}
            </span>}
          </span>
         </div>
        <div className='key-insight'>
          <label>Month Cashflow Generation</label>
          <span style={{
            display:'block',
            padding:'0',
            marginTop:'0.5em'}}>
            {keyInsights.cfGeneration && <img className='thumbs-positive-negative'
                                     src={showThumb(keyInsights.cfGeneration.month)}
                                     alt={keyInsights.cfGeneration.month >= 0? 'Up':'Down'}/>}
            {keyInsights.cfGeneration &&
             <span style={{color:keyInsights.cfGeneration.month >= 0? '#090909':'red',padding:'0'}}>
               {formatCurrency(keyInsights.cfGeneration.month,company.currency)}
            </span>}
          </span>
        </div>
        <div className='key-insight'>
          <label>Cash Balance</label>
          <span style={{
            display:'block',
            padding:'0',
            marginTop:'0.5em'}}>
            {keyInsights.balance &&
             <span style={{color:keyInsights.balance.cash > 0? '#090909':'red',padding:'0'}}>
               {formatCurrency(keyInsights.balance.cash,company.currency)}
             </span>}
          </span>
        </div>
        <div className='key-insight'>
          <label>YTD EBITDA</label>
          <span>{keyInsights.ebitda &&
                 <span style={{color:keyInsights.ebitda.ytd.value >= 0? '#090909':'red',padding:'0'}}>
                   {formatCurrency(keyInsights.ebitda.ytd.value,company.currency)}
                 </span>}
          </span>
          <div style={{paddingTop:'0.5em'}}>
            {keyInsights.ebitda &&
            <img className={showArrowClass(keyInsights.ebitda.ytd.difference)}
                 src={showArrow(keyInsights.ebitda.ytd.difference)}
                 alt={keyInsights.ebitda.ytd.difference>=0? 'Up':'Down'}/>}
            <span>{keyInsights.ebitda &&
              <span style={{color:keyInsights.ebitda.ytd.difference >= 0? '#090909':'red',padding:'0'}}>
                {formatCurrency(keyInsights.ebitda.ytd.difference,company.currency)}
                &nbsp;({parseFloat(keyInsights.ebitda.ytd.percentage).toFixed(1)}%)</span>}
            </span>
          </div>
        </div>
        <div className='key-insight'>
          <label>YTD Free Cashflow</label>
          <span style={{
            display:'block',
            padding:'0',
            marginTop:'0.5em'}}>
             {keyInsights.fcf && <img className='thumbs-positive-negative'
                                               src={showThumb(keyInsights.fcf.ytd)}
                                               alt={keyInsights.fcf.ytd >= 0? 'Up':'Down'}/>}
            {keyInsights.fcf &&
             <span style={{color:keyInsights.fcf.ytd >= 0? '#090909':'red',padding:'0'}}>
               {formatCurrency(keyInsights.fcf.ytd,company.currency)}
             </span>
            }</span>
        </div>
        <div className='key-insight'>
          <label>YTD Cashflow Generation</label>
          <span style={{
            display:'block',
            padding:'0',
            marginTop:'0.5em'}}>
            {keyInsights.cfGeneration && <img className='thumbs-positive-negative'
                                     src={showThumb(keyInsights.cfGeneration.ytd)}
                                     alt={keyInsights.cfGeneration.ytd >= 0? 'Up':'Down'}/>}
            {keyInsights.cfGeneration &&
             <span style={{color:keyInsights.cfGeneration.ytd >= 0? '#090909':'red',padding:'0'}}>
               {formatCurrency(keyInsights.cfGeneration.ytd,company.currency)}
            </span>}
          </span>
        </div>
        <div className='key-insight'>
          <label>Debt Balance </label>
          <span style={{
            display:'block',
            padding:'0',
            marginTop:'0.5em'}}>
            {keyInsights.balance &&
             <span style={{color:keyInsights.balance.debt >  0? 'red':'#090909',padding:'0'}}>
               {formatCurrency(keyInsights.balance.debt,company.currency)}
             </span>}
          </span>
        </div>
      </div>
      }
      {
        keyInsightsError && <ErrorResponse error={keyInsightsError}/>
      }
      </div>
      <hr className='separator-line' style={{margin:'2em 0 0em 0'}}/>
      <div>
        <h3 style={{fontSize:'1.2em',textAlign:'center',padding:'0.5em 0 0 0',color:'rgb(57, 80, 94)'}}>Key Insights for the Month and Year-to-Date (YTD)</h3>
        <div style={{padding:'1em 1em 0 1em'}}>
          <div className={`writeups-container`}>
            <div className={`writeup-wrapper heading`}>MONTH PROFITS (Vs same month of previous year)</div>
            <div className={`writeup-wrapper heading`}>YEAR TO DATE PROFITS
              &nbsp;({overallEbitdaContriWriteup.monthsYtd} {`${overallEbitdaContriWriteup.monthsYtd > 1 ?`months`:`month`}`} Vs same period of previous year) </div>
          </div>
          {overallEbitdaContriWriteupLoading && <div className={`align-loader`}><Loader type={'pulse'} size={10}/></div>}
          {(!overallEbitdaContriWriteupLoading && !overallEbitdaContriWriteupError) &&
           <div className={`writeups-container`}>
           <div className='writeup-wrapper'>
             <p>{ReactHtmlParser(overallEbitdaContriWriteup.ebitda_monthly)}</p>
             <p>{ReactHtmlParser(overallEbitdaContriWriteup.ebitda_margin_monthly)}</p>
           </div>
            <div className='writeup-wrapper'>
            <p>{ReactHtmlParser(overallEbitdaContriWriteup.ebitda_yearly)}</p>
            <p>{ReactHtmlParser(overallEbitdaContriWriteup.ebitda_margin_yearly)}</p>
            </div>
           </div>
          }
          {overallEbitdaContriWriteupError && <ErrorResponse error={overallEbitdaContriWriteupError}/> }
        </div>
      </div>
      <hr className='separator-line' style={{margin:'2em 0 0em 0'}}/>
      <div>
        <h3 style={{fontSize:'1.2em',textAlign:'center',padding:'0.5em 0 0 0',color:'rgb(57, 80, 94)'}}>Potential Cost Saving Opportunities</h3>
      </div>
      <p style={{textAlign: 'center',
        paddingBottom: '1em',
        fontSize: '1.1em'}}>The following cost categories have been affecting your profitability recently. They may present cost saving opportunities.</p>
        <ul style={{display:'flex',justifyContent:'flex-start'}}>

          {costSavingOpportunitiesLoading && <div className={`align-loader`}><Loader type={'pulse'} size={10}/></div>}
        {(!costSavingOpportunitiesLoading && !costSavingOpportunitiesError) && (
          costSavingOpportunities.categories &&
         costSavingOpportunities.categories.map((opportunity)=>(<li className='cost-saving-cat-title'>{opportunity}</li>)))
        }
          {(!costSavingOpportunitiesLoading && !costSavingOpportunitiesError) &&  !costSavingOpportunities.categories &&
           <div style={{height: '100px'}} className="chart-error error-icon">
             <span>There is not enough data provided for calculating cost saving opportunities.</span>
           </div>}
      </ul>
      {costSavingOpportunitiesError}
      {!costSavingOpportunitiesLoading && !costSavingOpportunitiesError && costSavingOpportunities.categories !==undefined &&
      <div>
      {(!costSavingOpportunitiesLoading && !costSavingOpportunitiesError) &&
        <div className={`doubleYAxis-charts-wrapper`}>
          {<div className={`doubleYAxis-charts-container`}>
            <LineChartDoubleYAxis
              dataValue={costSavingOpportunities.categories && costSavingOpportunities.categories[0]}
              percentageOf={'% of Sales'}
              formatter={(value) => formatCurrency(value, company.currency)}
              loading={costSavingOpportunitiesCat1Loading}
              error={costSavingOpportunitiesCat1Error}
              showDot={false}
              showBrush={false}
              chartHeight={160}
              wrapperStyle={{fontSize: '10px', width: '100%', maxWidth: '300px'}}
              tooltipTextForInsights={company.currency}
              data={costSavingOpportunitiesCat1}/>
          </div>}
          {<div className={`doubleYAxis-charts-container`}>
            <LineChartDoubleYAxis
              dataValue={costSavingOpportunities.categories && costSavingOpportunities.categories[1]}
              percentageOf={'% of Sales'}
              formatter={(value) => formatCurrency(value, company.currency)}
              loading={costSavingOpportunitiesCat2Loading}
              error={costSavingOpportunitiesCat2Error}
              showDot={false}
              showBrush={false}
              chartHeight={160}
              wrapperStyle={{fontSize: '10px', width: '100%', maxWidth: '300px'}}
              tooltipTextForInsights={company.currency}
              data={costSavingOpportunitiesCat2}/>
          </div>}
          {<div className={`doubleYAxis-charts-container`}>
            <LineChartDoubleYAxis
              dataValue={costSavingOpportunities.categories && costSavingOpportunities.categories[2]}
              percentageOf={'% of Sales'}
              formatter={(value) => formatCurrency(value, company.currency)}
              loading={costSavingOpportunitiesCat3Loading}
              error={costSavingOpportunitiesCat3Error}
              showDot={false}
              showBrush={false}
              chartHeight={160}
              wrapperStyle={{fontSize: '10px', width: '100%', maxWidth: '300px'}}
              tooltipTextForInsights={company.currency}
              data={costSavingOpportunitiesCat3}/>
          </div>}
          {<div className={`doubleYAxis-charts-container`}>
            <LineChartDoubleYAxis
              dataValue={costSavingOpportunities.categories && costSavingOpportunities.categories[3]}
              percentageOf={'% of Sales'}
              formatter={(value) => formatCurrency(value, company.currency)}
              loading={costSavingOpportunitiesCat4Loading}
              error={costSavingOpportunitiesCat4Error}
              showDot={false}
              showBrush={false}
              chartHeight={160}
              wrapperStyle={{fontSize: '10px', width: '100%', maxWidth: '300px'}}
              tooltipTextForInsights={company.currency}
              data={costSavingOpportunitiesCat4}/>
          </div>}
          {<div className={`doubleYAxis-charts-container`}>
            <LineChartDoubleYAxis
              dataValue={costSavingOpportunities.categories && costSavingOpportunities.categories[4]}
              percentageOf={'% of Sales'}
              showDot={false}
              formatter={(value) => formatCurrency(value, company.currency)}
              loading={costSavingOpportunitiesCat5Loading}
              error={costSavingOpportunitiesCat5Error}
              chartHeight={160}
              showBrush={false}
              wrapperStyle={{fontSize: '10px', width: '100%', maxWidth: '300px'}}
              tooltipTextForInsights={company.currency}
              data={costSavingOpportunitiesCat5}/>
          </div>}
        </div>
      }
      </div>}
      <hr className='separator-line' style={{margin:'2em 0 0em 0'}}/>

      <div>
        <h3 style={{fontSize:'1.2em',textAlign:'center',padding:'0.5em 0 0 0',color:'rgb(57, 80, 94)'}}>Trends and Liquidity Charts </h3>
      </div>
      {
        <div className={`line-charts-wrapper`}>
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Sales Evolution Monthly</span>
          </div>}
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>EBITDA Evolution Monthly</span>
          </div>}
        </div>
      }
      {
       <div className={`line-charts-wrapper`}>
         {<div className={`line-chart-container`}>
           <LineChartDoubleYAxis
             percentageOf={'Sales as % of sales'}
             dataValue={'Sales'}
             showGrid
             showLegend
             formatter={(value)=>formatCurrency(value,company.currency)}
             loading={salesEvolutionMonthLoading}
             error={salesEvolutionMonthError}
             data={salesEvolutionMonth}/>
         </div>}
         {<div className={`line-chart-container`}>
           <LineChartDoubleYAxis
             percentageOf={'EBITDA as % of sales'}
             dataValue={'EBITDA'}
             showGrid
             formatter={(value)=>formatCurrency(value,company.currency)}
             loading={overallEbitdaEvolutionMonthLoading}
             error={overallEbitdaEvolutionMonthError}
             data={overallEbitdaEvolutionMonth}/>
         </div>}
       </div>}
      {<hr className='separator-line' style={{margin:'2em 0 0em 0'}}/>}
      {
        <div className={`line-charts-wrapper`}>
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Monthly Net Income Evolution</span>
          </div>}
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Cash & Cash Equivalents</span>
          </div>}
        </div>
      }
      {
       <div className={`line-charts-wrapper`}>
         {<div className={`line-chart-container`}>
           <LineChartDoubleYAxis
             percentageOf={'Net Income as % of sales'}
             dataValue={'Net Income'}
             showGrid
             formatter={(value)=>formatCurrency(value,company.currency)}
             loading={netIncomeEvolutionMonthLoading}
             error={netIncomeEvolutionMonthError}
             data={netIncomeEvolutionMonth}/>
         </div>}
         {<div className={`line-chart-container`}>
           <LineChartDoubleYAxis
             percentageOf={'Cash as % of sales'}
             dataValue={'Cash'}
             showGrid
             formatter={(value)=>formatCurrency(value,company.currency)}
             loading={cashEquivalentsChartLoading}
             error={cashEquivalentsChartError}
             data={cashEquivalentsChart}/>
         </div>}
       </div>}

      {<hr className='separator-line' style={{margin:'2em 0 0 0'}}/>}
      {/*CF generation charts*/}
      {
        <div className={`line-charts-wrapper`}>
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Cashflow Generation Monthly</span>
          </div>}
          {<div className='lineChartHeading'>
            <span className='lineChartHeadingLabel'>Cashflow Generation YTD</span>
          </div>}
        </div>}
      {
       <div className={`line-charts-wrapper`}>
         {<div className={`line-chart-container`}>
           <WaterfallChart
             dataValue={'CF Summary'}
             formatter={(value)=>formatCurrency(value,company.currency)}
             loading={ categoryCfGenerationMonthlyLoading}
             error={categoryCfGenerationMonthlyError}
             data={categoryCfGenerationMonthlyChart} />
         </div>}
         {<div className={`line-chart-container`}>
           <WaterfallChart
             dataValue={'CF Summary'}
             formatter={(value)=>formatCurrency(value,company.currency)}
             loading={ categoryCfGenerationYTDLoading}
             error={categoryCfGenerationYTDError}
             data={categoryCfGenerationYTDChart} />

         </div>}
       </div>}
      {<hr className='separator-line' style={{margin:'2em 0 0 0'}}/>}
      {
       <div className={`line-charts-wrapper`} >
         {<div className='lineChartHeading'>
           <span className='lineChartHeadingLabel'>CF from Operations - Last Twelve Months</span>
         </div>}
         {<div className='lineChartHeading'>
           <span className='lineChartHeadingLabel'>Cashflow Generation - Last Twelve Months</span>
         </div>}
       </div>}
      {
       <div className={`line-charts-wrapper`}>
         {<div className={`line-chart-container`}>
           <BarChartOneCategory
             showLegend={false}
             showBrush={false}
             dataKey={"date"}
             formatter={(value)=>formatCurrency(value,company.currency)}
             loading={lTMPrevChartLoading}
             error={lTMPrevChartError}
             data={lTMPrevChart}/>
         </div>}
         {<div className={`line-chart-container`}>
           <SimpleBarChart
             dataKey={'date'}
             dataValue={'CF Summary'}
             formatter={(value)=>formatCurrency(value,company.currency)}
             loading={ cfGenerationChartTwelveMonthsLoading}
             error={cfGenerationChartTwelveMonthsError}
             data={cfGenerationChartTwelveMonths} />
         </div>}
       </div>}
       <div style={{paddingTop:'1em'}}>
       </div>
    </div>
  )
}
export default KeyInsights;
