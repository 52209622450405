import { css } from "@emotion/react";

export const LoginCSS = css`
    min-height: calc(100vh - 60px);
    .logo-horizontal {
        width: 190px;
        margin: 1em;
    }

    #qokoon-symbol {
        width: 80px;
        height: 80px;
        margin: 0 auto;
        display: block;
        margin-bottom: 1em;
        margin-top: 10em;
    }

    .login-container {
        width: 24em;
        height: 12em;
        margin: 0 auto;
        border: 1px solid #ABB7C0;
        border-radius: 5px;
        box-sizing: border-box;
        
        .sign-in-container {
            padding: 17px 52px 20px 50px;

            .sign-in {
                height: 33px;
                width: 94%;
                color: #080D37;
                font-size: 28px;
                font-weight: 500;
                letter-spacing: 0.15px;
                line-height: 33px;
                margin: 0;
                text-align: center;
                margin-bottom: 0.5em;
            }

            .qokoon-sign-in-block {
                height: 56%;
                margin-top: 2em;

                .input-field {
                    width: 98%;
                    margin-bottom: 2em;
                    padding-bottom: 0.6em;
                    font-size: 16px;
                    line-height: 19px;
                    border: none;
                    border-bottom: 1px solid #384751;

                    &:focus {
                        outline: none;
                    }
                }

                .sign-in-options {
                    width: 100%;
                    float: left;
                    margin-bottom: 2em;

                    .stay-signed-in {
                        width: 50%;
                        float: left;

                        & label {
                            vertical-align: top;
                            margin-left: 5px;
                            cursor: pointer;
                        }

                        & input {
                            cursor: pointer;
                        }
                    }

                    .forgot-password {
                        width: 50%;
                        float: left;
                        text-align: right;
                        color: #305A80;
                        text-decoration: underline;
                        line-height: 18px;
                        height: 18px;
                        cursor: pointer;
                        letter-spacing: 0.13px;
                    }
                }

                .sign-in-submit {
                    width: 14em;
                    height: 3em;
                    border-radius: 3px;
                    background-color: #305A80;
                    border: none;
                    color: #fff;
                    font-size: 16px;
                    font-weight: bold;
                    cursor: pointer;
                    letter-spacing: 0.14px;
                }
            }
            .sign-in-source-wrapper {
                width: 100%;
                padding-top: 30px;

                .sign-in-source-heading {
                    color: #080D37;
                    font-weight: 500;
                    font-size: 15px;
                    margin-bottom: 2em;

                    .trailing-line {
                        float: right;
                        padding-top: 10px;
                        width: 16em;
                        border-bottom: 1px solid #D8DFE7;
                    }
                }

                .sign-in-source {
                    box-sizing: border-box;
                    height: 54px;
                    width: 165px;
                    border: 1px solid #ABB7C0;
                    border-radius: 3px; 
                    margin: 0 auto;                                    

                    #xero-sso {
                        padding: 0 2em;
                    }

                    #qb-logo {
                        height: 24px;                  
                    }

                    #xero-logo {
                        width: 30px;
                        height: 30px;
                    }

                    .sso-link {
                    display:flex;
                    align-items:center;
                    justify-content: center;
                    height:54px;
                        span {
                            line-height: 3.5em;
                            color: #080D37;
                            font-family: Roboto;
                            font-size: 15px;
                            font-weight: bold;
                            padding-left: 5px;
                        }
                    }
                }
            }
        }

        .login-footer {
            width: 100%;
            height: 4.4em;
            float: left;
            background: #D8DFE7;
            border-bottom-right-radius: 3px;
            border-bottom-left-radius: 3px;

            p {
                font-size: 15px;
                font-weight: 500;
                text-align: center;
                color: #080D37;
                line-height: 3em;
                letter-spacing: 0.14px;

                span {
                    margin-left: 1em;
                    color: #305A80;
                    text-decoration: underline;
                    font-weight: initial;
                    letter-spacing: 0.13px;
                }
            }
        }
    }
`;