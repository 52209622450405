/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import qokoon_logo from '../img/logo.svg';
import qokoon_symbol from '../img/qokoon-logo.png';
import { LoginCSS } from './LoginCSS';
import xero_logo from '../img/xero_logo.png';
// import qb_logo from '../img/qb-logo.svg';
import withErrorBoundary from '../error/ErrorBoundary';


const OAuthData = {
    xero: {
        scopes: `${process.env.REACT_APP_XERO_SCOPES}`,
        clientID: `${process.env.REACT_APP_XERO_CLIENT_ID}`,
        redirectURI: `${process.env.REACT_APP_BACKEND_BASE_URL}/xeroredirect`
    },
    qb: {
        scopes: `${process.env.REACT_APP_QB_SCOPES}`,
        clientID: `${process.env.REACT_APP_QB_CLIENT_ID}`,
        redirectURI: `${process.env.REACT_APP_BACKEND_BASE_URL}/qbredirect`,
        state: `${process.env.REACT_APP_QB_STATE}`
    }
};


function Login() {
    const xeroOAuthLink = `${process.env.REACT_APP_XERO_HOST}/identity/connect/authorize?response_type=code&client_id=${OAuthData.xero.clientID}&redirect_uri=${OAuthData.xero.redirectURI}&scope=${OAuthData.xero.scopes}`;
   // const QBOAuthLink = `https://appcenter.intuit.com/app/connect/oauth2?client_id=${OAuthData.qb.clientID}&scope=${OAuthData.qb.scopes}&redirect_uri=${OAuthData.qb.redirectURI}&response_type=code&state=${OAuthData.qb.state}`;

    return (
        <div className="login-wrapper" css={LoginCSS}>
            <img id="qokoon-logo" className="logo-horizontal" src={qokoon_logo} alt="" />
            <img id="qokoon-symbol" src={qokoon_symbol} alt="" />
            <div className="login-container">
                <div className="sign-in-container">
                    <h4 className="sign-in">Sign in</h4>
                    <div className="sign-in-source-wrapper">
                        <div className="sign-in-source">
                            <div id="xero-sso">
                                <a href={xeroOAuthLink} className="sso-link" data-testid="xero-login-link">
                                    <img id="xero-logo" src={xero_logo} alt="" />
                                    <span className="xero-sso-label">Xero</span>
                                </a>
                            </div>
                        </div>
                        {/*<div className="sign-in-source">*/}
                        {/*    <div id="qb-sso">*/}
                        {/*        <a href={QBOAuthLink} className="sso-link">*/}
                        {/*            <img id="qb-logo" src={qb_logo} alt="" />*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withErrorBoundary(Login);
