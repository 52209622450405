import './Error.css';

function Error() {
    return (
        <div className="error">
            <strong data-testid='error-message' className="error-message">
                Oops! It seems that you are not authorised to use this application.
                You can register your interest to be notified when we launch here <a href="https://qokoon.com" target="_blank" rel="noreferrer">www.qokoon.com </a>
            </strong>
        </div>
    );
}

export default Error;
