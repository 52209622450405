import React, {useEffect, useState} from 'react';
import CustomMonthYearSelect from "../custom-month-year-select";
import {useDispatch, useSelector} from 'react-redux';
import './subHeader.css';
import {Mixpanel} from "../mixpanel";
import { timeSince } from '../util/dateHelper';
import CustomSelect from "../custom-select";
import {useHistory} from "react-router-dom";
import useGet from "../hooks/use-get";

function SubHeader({text,heading=''}) {
    const monthsInAnYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dispatch = useDispatch();
    //const location = useLocation();
    const history = useHistory();
    const company = useSelector(state => state.selectedCompany);
    //const selectedEndYear = useSelector(state => state.endDate.year);
    //const selectedEndMonth = useSelector(state => state.endDate.month);
    const selectedDate = useSelector(state => state.endDate);
    const lastUpdated = company.updatedOn;

    const [monthOptions, setMonthOptions] = useState(getMonthOptionsBasedOnYearSelected(selectedDate.year))

    const companyList = useSelector(state => state.companyList);
    const [ytdMonth,setYtdMonth]=useState('');
    const getDate=(date)=> `${date.month} ${date.year.slice(-2)}`;

    const [ytdMonthResponse, ytdMonthLoading, ytdMonthError,] = useGet({
        url: `${process.env.REACT_APP_BACKEND_BASE_URL}/months-ytd?companyId=${company.companyId}`+
             `&date=${getDate(selectedDate)}`,
    });

    useEffect(()=>{
            if (!ytdMonthLoading && ytdMonthResponse) {
                setYtdMonth(ytdMonthResponse.monthsYtd);
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ytdMonthResponse, ytdMonthLoading, ytdMonthError,company,selectedDate]);

    function getMonthOptionsBasedOnYearSelected(selectedYear) {
        if (parseInt(selectedYear) < new Date().getFullYear()) {
            return monthsInAnYear.map(month => ({
                label: month,
                selectable: true
            }))
        } else {
            const lastSelectableMonthIndex = new Date().getMonth();
            return monthsInAnYear.map(month => {
                if (monthsInAnYear.indexOf(month) <= lastSelectableMonthIndex) {
                    return {
                        label: month,
                        selectable: true
                    }
                } else {
                    return {
                        label: month,
                        selectable: false
                    }
                }
            })
        }
    }
    const handleChange =(text)=>{
        const selectedText = text;
        Mixpanel.track('Company Selected',{company:companyList.find((obj) => obj.companyName === selectedText)});
        dispatch({type:'selectCompany',payload:companyList.find((obj) => obj.companyName === selectedText)});
        dispatch(
          {
              type:'urlChange',
              payload:{
                  section: 'insights',
                  sheet:null,
                  category:null,
                  subCategory: null,
              }});
        history.push('/insights');
    }

    const handleYearChange = (selectedYearString) => {
        setMonthOptions(getMonthOptionsBasedOnYearSelected(selectedYearString));
        Mixpanel.track('Year Change',{year:selectedYearString});
        dispatch({type: 'changeEndYear', payload: selectedYearString});
    }
    
    return (
        <div>

            <div className="subHeader">
                { Object.entries(company).length > 0 && history.location.pathname !== '/' ?
                  <CustomSelect
                    selectedValue={company.companyName}
                    options={companyList.map(obj=> ({label: obj.companyName, selectable: true}))}
                    className={`companySwitcher`}
                    callback={handleChange}/>
                  : ''}
                {heading !=='' && <h3 className='subHeaderHeadingText'>{heading}</h3>}
                <div className='header-center-section'>
                <div className='subheader-date-picker'>
                    <span className="subheaderText">{text}</span>
                    <CustomMonthYearSelect monthOptions={monthOptions}
                                           selectedMonth={selectedDate.month}
                                           selectedYear={selectedDate.year}
                                           callbackMonthChange={(text) => {
                                               Mixpanel.track('Month Change',{month:text});
                                               dispatch({type: 'changeEndMonth', payload: text})}}
                                           callBackYearChange={handleYearChange}
                    />
                </div>
                <div className='ytd-month'>
                    (YTD is {!ytdMonthLoading && <span>{ytdMonth} {parseInt(ytdMonth) === 1 ?'month':'months'})</span>}
                    {!ytdMonthLoading && ytdMonthError && <span className="chart-error">Error fetching data. Try again later</span>}
                </div>
                </div>
            <div style={{color:'#39505E',fontSize:'0.9em',flex: '1 1 20%'}}>
                Last updated : <span style={{fontSize:'1em',color:'#090909'}}>{timeSince(lastUpdated)} ago</span>
            </div>

            </div>
        </div>
    )
}

export default SubHeader;
