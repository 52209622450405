import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';
import React, {useEffect, useState} from 'react';
import {PieChartLabel, PieChartLabelPercent} from './label';
import Loader from "../reusables/Loader";
import './simplePieChart.css';
import ErrorResponse from "../error-response/error-response";

const COLORS = ['#ff8042','#0088FE', '#00C49F', '#FFBB28','#3366CC','#8884d8'];

const CustomTooltip = (props) => {
  const { active, payload,formatter } = props;
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{fontSize:'0.8em'}}>
        <p className="tooltip-heading">{`${payload[0].name}`}</p>
        <div style={{color: payload[0].stroke}}>
          <span className="tooltip-label">Amount : </span>
          <span className="tooltip-value">{formatter(payload[0].value)}</span>
        </div>
      </div>
    );
  }
  return null;
};

const SimplePieChart = ({
  items, titleKey, isLoading, error,formatter,containerHeight=300,pieChartHeight=260,dataTestId,
}) =>{
  const [showLabel,setShowLabel] =useState(true);
  const checkLabelItems = ()=>{
    if(items.length !==1) {
      const sum = items.reduce((acc, item) => {
        return acc + item.value
      }, 0);
      items.forEach(item => {
        if ((sum / item.value) > 90) {
          if (items.length > 2) {
            setShowLabel(false);
          }
        }
      })
    }
  }

  useEffect(()=>{
    setShowLabel(true);
    if(items) {
      checkLabelItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[showLabel,items]);

  return(
    <div data-testid={dataTestId}>
      {isLoading && <div style={{margin:'1em 0 2em 0'}}><Loader type={'pulse'} dataTestId={`${dataTestId}-loading`} size={10}/></div>}
      {error &&  <ErrorResponse dataTestId={`${dataTestId}-error`} error={error}/>}
      {!error && <span>
    {!isLoading &&
      <div>
        {titleKey.length >0 &&
         <div className="pieChartHeading">
            {titleKey}
          </div>}
    <div>
      {items && Object.keys(items).length !== 0 ? <ResponsiveContainer width="100%" minHeight={containerHeight}>
          <PieChart height={pieChartHeight}>
            <Pie
              minAngle={5}
              data={items}
              cx="50%"
              cy={120}
              outerRadius={80}
              startAngle={-270}
              fill="#007AD5"
              label={showLabel ? <PieChartLabel/>: <PieChartLabelPercent/>}
              // label={PieChartLabel}
              isAnimationActive={false}
              dataKey="value"
              nameKey="name"
            >
              {
                items.map((entry,index) => {
                  if(entry.value <=0){
                    return <g key={index}/>
                  }
                    return (<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>)
                  }
                )
              }
            </Pie>
            <Legend wrapperStyle={{bottom:'0',maxWidth:'90%'}} iconType={'circle'} formatter={(value)=> {return value.length<40? <span style={{fontSize:'0.9em'}}>{value}</span>: <span style={{fontSize:'0.9em'}}>{value.slice(0,38)+'...'}</span> }} fontSize={'0.9em'} align={"center"}/>
            <Tooltip content={<CustomTooltip/>} contentStyle={{fontSize:'0.9em',borderRadius:'3px'}} formatter={value => formatter(value)} />
          </PieChart>
        </ResponsiveContainer> :
        <div className={'pieChartNoData'}>{}</div>
      }
    </div>
      </div>
    }</span>}
    </div>)};

export default SimplePieChart;
