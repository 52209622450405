import React from 'react';
import {useEffect} from "react";
import './left-navigation.css';
import Loader from "../reusables/Loader";
import {useHistory, useLocation,} from "react-router-dom";
import useGet from '../hooks/use-get';
import {useDispatch, useSelector} from "react-redux";
import CustomLink from "../custom-link";
import * as QueryString from "query-string";

function LeftNavigation(){
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const {tab,category,subCategory}= QueryString.parse(location.search);
  const selectedSection = location.pathname.split('/')[1];
  const selectedNavigation = useSelector(({selectedNavigation}) => selectedNavigation);
  const selectedSheet =location.pathname.split('/')[2]?location.pathname.split('/')[2]: selectedNavigation.sheet;
  const sectionsList = [
    'insights',
    'analyse',
    'reports',
    'anomalies'
  ];

  if (tab==='true'){
    dispatch({
      type:'urlChange',
      payload:{
        section: selectedSection,
        sheet:selectedSheet,
        category:category? category:null,
        subCategory: subCategory? subCategory:null,
      }});
    if(selectedSection === 'analyse'){
      history.push(`/${selectedSection}/${selectedSheet}`);
    }
    else{
      history.push(`/${selectedSection}`);
    }
  }

  if(!sectionsList.includes(selectedSection) || Object.keys(selectedNavigation).length === 0){
    history.push('/');
  }

  const selectedCategory =selectedNavigation.category;
  const selectedSubCategory = selectedNavigation.subCategory;
  const selectedCompany = useSelector(({selectedCompany}) => selectedCompany);
  const analysisNavSaved = useSelector(({leftNavigation}) => leftNavigation);

  const activeCategory ={section: selectedSheet,category: selectedCategory ? selectedCategory : null,subCategory : selectedSubCategory ? selectedSubCategory: null};

  // const isSkipped =()=>{
  //   if(analysisNavSaved.response) {
  //     return selectedCompany.companyId === analysisNavSaved.companyId;
  //   }
  //   return false;
  // }

  const [analysisNavResponse, analysisNavLoading, analysisNavError] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/analysis/candidates?companyId=${selectedCompany.companyId}`
  });

  const saveNavigation = () =>{
    dispatch({type:'setLeftNavigation',payload:{response: analysisNavResponse, companyId: selectedCompany.companyId}})
  }

  useEffect(() => {
      if (!analysisNavLoading && analysisNavResponse && Object.keys(analysisNavResponse).length !== 0) {
        saveNavigation();
      }
// eslint-disable-next-line
  }, [analysisNavResponse,analysisNavLoading, analysisNavError]);

  return(
    <aside className="left-navigation">
      <NavigationMenu
        selectedSection={selectedSection}
        activeCategory={activeCategory}
        selectedCompany={selectedCompany}
        loading={analysisNavLoading}
        error={analysisNavError}
        response={analysisNavResponse}
        analysisNavSaved={analysisNavSaved}
      />
    </aside>
  )
}

const NavigationMenu = ({selectedSection,activeCategory,analysisNavResponse,analysisNavLoading,analysisNavSaved})=>{
  const createAnalysisNavigation =()=>{
    const analysisList =[];
    const response = (analysisNavResponse) ? analysisNavResponse: analysisNavSaved.response ;
    if(response !== undefined) {
      for (const [sectionName, sectionList] of Object.entries(response)) {
        let categoryList;
        if (sectionList) {
          categoryList = sectionList.map((category, index) => {
            let subCategoryList = [];
            if (category.subcategories.length) {
              subCategoryList = category.subcategories.map((text, index) => {
                return (
                  <li key={`subcat-${index}`} className="subCategoryListItem">
                    <CustomLink
                      url={`/analyse/${sectionName}`}
                      name={text}
                      dispatchAction={{
                        type: 'urlChange',
                        payload: {
                          section: 'analyse',
                          sheet: sectionName,
                          category: category.name,
                          subCategory: text,
                        }}
                      }
                    wrapperClass={'subCategoryLink'}
                    />
                  </li>)
              })
            }
            return (
              <ul key={`cat-${index}`}
                  className={`subCategoryList ${activeCategory.category === category.name ? 'active' : ''} ${category.subcategories.length > 0 ? 'arrow-right' : ''}`}
                  onMouseOver={showSubNav}
                  onMouseOut={hideSubNav}>
                <p className={`subCategoryListText ${activeCategory.subCategory === null ? 'active' : ''}`}>
                  <CustomLink
                    name={category.name}
                    url={`/analyse/${sectionName}`}
                    dispatchAction={{
                      type: 'urlChange',
                      payload: {
                        section: 'analyse',
                        sheet: sectionName,
                        category: category.name,
                        subCategory: null,
                      }
                    }}
                  />
                </p>
                {activeCategory.subCategory !== null && <p
                  className={`selectedCategory ${activeCategory.category === category.name ? 'active' : ''}`}>{activeCategory.subCategory}</p>}
                {subCategoryList.length > 0 && <div className="subCategoryListItemWrapper">{subCategoryList}</div>}
              </ul>
            )
          })
        }
        analysisList.push(
          <ul key={`${sectionName}-key`} id={sectionName}
              className={`categoryList ${activeCategory.section === sectionName ? 'show' : ''}`}>
            <p onClick={toggleCategory} className="categoryListName">{sectionName}</p>
            <div className={"categoryListItemWrapper"}>{categoryList}</div>
          </ul>
        );
      }
    }
    return analysisList;
  }

  const showSubNav =(e) =>{
    e.currentTarget.classList.add('hovered');
    const top = e.currentTarget.getBoundingClientRect().top;
    const subCategoryList = e.target.parentElement.querySelector('.subCategoryListItemWrapper');
    if(subCategoryList){
      const subCatListHeight = subCategoryList.clientHeight;
      const pos = top - subCatListHeight/2;
      subCategoryList.style.top=  pos > 100? pos+'px': '100px';
    }
  }

  const hideSubNav =(e) =>{
    e.currentTarget.classList.remove('hovered');
  }
  const toggleCategory = (e)=>{
    Array.from(e.currentTarget.parentElement.parentElement.children).forEach(function(element) {
      if(element !== e.currentTarget.parentElement) {
        element.classList.remove('show');
      }
    });
    if(e.currentTarget.parentElement.classList.contains('show')){
      e.currentTarget.parentElement.classList.remove('show')
    }
    else {e.currentTarget.parentElement.classList.add('show')
    }
  }
  return (
    <ul className="navigationMenu">
      <li className={`topLevelCategory ${(selectedSection==='insights') ? 'active':''}`}>
        <CustomLink
          name={'Key Insights'}
          url={'/insights'}
          dispatchAction={{
            type:'urlChange',
            payload:{
              section: 'insights',
              sheet:null,
              category:null,
              subCategory: null,
            }}}
        />
      </li>

      <li className={`topLevelCategory`}>

        <CustomLink
          name={'Analyse'}
          url={`/analyse/Profit%20and%20Loss`}
          dispatchAction={{
            type:'urlChange',
            payload:{
              section: 'Analyse',
              sheet:'Profit and Loss',
              category:'P&L Summary',
              subCategory: null,
            }}}
        />
        </li>
      {
        analysisNavLoading  && (activeCategory.section !== null) && <Loader type={'pulse'} size={12}/>
      }
      { activeCategory.category && <li className="analyticsSection">{!analysisNavLoading && createAnalysisNavigation()}</li>}
      <li  className={`topLevelCategory ${(selectedSection ==='anomalies') ? 'active':''}`}>
        <CustomLink
          name={'Anomalies'}
          url={'/anomalies'}
          dispatchAction={{
            type:'urlChange',
            payload:{
              section: 'anomalies',
              sheet:null,
              category:null,
              subCategory: null,
            }}}
        />
      </li>
      <li className={`topLevelCategory ${(selectedSection==='reports') ? 'active':''}`}>
        <CustomLink
          url={'/reports'}
          name={'Reports'}
          dispatchAction={{
              type:'urlChange',
              payload:{
                section: 'reports',
                sheet:null,
                category:null,
                subCategory: null,
              }}
          }/>
      </li>
    </ul>
  )
}
export default LeftNavigation;