import {createStore} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const monthNames = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
let d = new Date();
d.getDate() <= 15 ? d.setMonth(d.getMonth() - 2): d.setMonth(d.getMonth() - 1);

const persistConfig = {
  key: 'root',
  storage,
}

const initialState= {
  companyList:[],
  selectedCompany:{},
  leftNavigation:{},
  selectedNavigation:{},
  startDate:{
    month:'Jan',
    year:'2020'
  },
  endDate: {
    month: monthNames[d.getMonth()],
    year:  d.getFullYear().toString()
  },
  IsValidUser:false

}
export {initialState};
const persistedReducer = persistReducer(persistConfig, reducer)

export function reducer(state= initialState,action) {
  switch (action.type) {
    case "selectCompany":
      return {...state, selectedCompany: action.payload}
    case "setCompanyData":
      return {...state, companyList:action.payload}
    case "changeStartMonth":
      return {...state, startDate:{ month: action.payload, year: state.startDate.year}}
    case "changeStartYear":
      return {...state, startDate:{ year: action.payload, month: state.startDate.month}}
    case "changeEndMonth":
      return {...state, endDate:{ month: action.payload, year: state.endDate.year}}
    case "changeEndYear":
      return {...state, endDate:{ year: action.payload, month: state.endDate.month}}
    case "setLeftNavigation":
      return {...state, leftNavigation: action.payload}
    case "modifyCompanyDetails":
      return {...state,companyList: state.companyList.map((company)=>{
        if(company.companyId === action.payload.companyId){
          company.updatedOn = action.payload.response.updatedOn;
        }
        return company;
        })}
    case "urlChange":
      return {
        ...state,selectedNavigation: action.payload
      }
    case "setIsValidUser":
      return {...state,IsValidUser:action.payload}
    case "RESET":
      return {...initialState, IsValidUser: true}
    default:
      return state
  }
}
const fn = () => {
  let store = createStore(persistedReducer)
  let persistor = persistStore(store)
  return { store, persistor }
}
export default fn;
