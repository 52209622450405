import React, {useEffect, useState} from "react";
import SubHeader from "../subHeader";
import {useSelector} from "react-redux";
import useGet from "../hooks/use-get";
import Loader from "../reusables/Loader";
import './anomalies.css';
import ReactHtmlParser from "react-html-parser";
import ErrorResponse from "../error-response/error-response";

const Anomalies =()=>{
  const company = useSelector(({selectedCompany}) => selectedCompany);
  const selectedDate = useSelector(({endDate}) => endDate);
  const getDate=(date)=> `${date.month} ${date.year.slice(-2)}`;
  const [anomaliesPnl,setAnomaliesPnl]=useState({});
  const [anomaliesBS,setAnomaliesBS]=useState({});
  const [anomaliesCF,setAnomaliesCF]=useState({});

  const [anomaliesPnlResponse, anomaliesPnlLoading, anomaliesPnlError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/anomalies?companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}&sheet=PNL`});
  const [anomaliesBSResponse, anomaliesBSLoading, anomaliesBSError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/anomalies?companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}&sheet=BS`});
  const [anomaliesCFResponse, anomaliesCFLoading, anomaliesCFError,] = useGet({
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}/anomalies?companyId=${company.companyId}`+
         `&date=${getDate(selectedDate)}&sheet=CF`});

  useEffect(()=>{
    if (!anomaliesPnlLoading && anomaliesPnlResponse) {
      setAnomaliesPnl(anomaliesPnlResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[anomaliesPnlResponse,
    anomaliesPnlLoading,
    anomaliesPnlError,
  ]);
  useEffect(()=>{
    if (!anomaliesBSLoading && anomaliesBSResponse) {
      setAnomaliesBS(anomaliesBSResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[anomaliesBSResponse,
    anomaliesBSLoading,
    anomaliesBSError,
  ]);
  useEffect(()=>{
    if (!anomaliesCFLoading && anomaliesCFResponse) {
      setAnomaliesCF(anomaliesCFResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[anomaliesCFResponse,
    anomaliesCFLoading,
    anomaliesCFError,
  ]);
  return (
  <div className="content-wrapper" style={{marginTop:'57px'}}>
    <SubHeader text={'For'} heading=''/>
    <div className='page-title'>
      <h5 style={{margin: '0'}}>Anomalies</h5>
      <span style={{
        fontWeight:'400',
        fontSize:'1em'}}
      >&nbsp;(showing only top 5)</span>
    </div>
    <div style={{paddingTop: '5em'}}>
      <h3 style={{fontSize:'1.2em',textAlign:'center',padding:'0.5em 0 0.5em 0',color:'#39505E'}}>Profit & Loss Anomalies</h3>
      {anomaliesPnlLoading && <div className={`align-loader`}><Loader dataTestId={'anomaliesPnl'} type={'pulse'} size={10}/></div>}
      {(!anomaliesPnlLoading && !anomaliesPnlError) &&
       <div className={`anomalies-top-wrapper`}>
         <div className='anomalies-section-bg'>
           <div className={`writeups-container heading`}>
             <p className={`writeup-wrapper heading`}>{ReactHtmlParser(anomaliesPnl.month_abs?.increased.title)}</p>
             <p className={`writeup-wrapper heading`}>{ReactHtmlParser(anomaliesPnl.month_abs?.decreased.title)}</p>
           </div>
           <div className={`writeups-container`}>
              <div className='anomalies-wrapper'>
                <ul>{anomaliesPnl.month_abs?.increased.value.map((buttletPoint,index) =>
                  <li key={index}>{index+1}. {ReactHtmlParser(buttletPoint)}</li>)}
                  {anomaliesPnl.month_abs?.increased.value.length === 0 && <span className='not-found-text'>No anomalies found</span>}
                </ul>
              </div>
              <div className='anomalies-wrapper'>
                <ul>{anomaliesPnl.month_abs && anomaliesPnl.month_abs.decreased.value.map((buttletPoint,index) =>
                  <li key={index}>{index+1}. {ReactHtmlParser(buttletPoint)}</li>)}
                  {anomaliesPnl.month_abs?.decreased.value.length === 0 && <span className='not-found-text'>No anomalies found</span>}
                </ul>
              </div>
            </div>
         </div>
         <div className='anomalies-section-bg'>
           <div className={`writeups-container heading`}>
             <p className={`writeup-wrapper heading`}>{ReactHtmlParser(anomaliesPnl.month_cos?.increased.title_inc)}</p>
             <p className={`writeup-wrapper heading`}>{ReactHtmlParser(anomaliesPnl.month_cos?.decreased.title_dec)}</p>
           </div>
           <div className={`writeups-container`}>
             <div className='anomalies-wrapper'>
               <ul>{anomaliesPnl.month_cos?.increased.value.map((buttletPoint,index) =>
                 <li key={index}>{index+1}. {ReactHtmlParser(buttletPoint)}</li>)}
                 {anomaliesPnl.month_cos?.increased.value.length === 0 && <span className='not-found-text'>No anomalies found</span>}
               </ul>
             </div>
             <div className='anomalies-wrapper'>
               <ul>{anomaliesPnl.month_cos && anomaliesPnl.month_cos.decreased.value.map((buttletPoint,index) =>
                 <li key={index}>{index+1}. {ReactHtmlParser(buttletPoint)}</li>)}
                 {anomaliesPnl.month_cos?.decreased.value.length === 0 && <span className='not-found-text'>No anomalies found</span>}
               </ul>
             </div>
           </div>
         </div>
         <div className='anomalies-section-bg'>
           <div className={`writeups-container heading`}>
             <p className={`writeup-wrapper heading`}>{ReactHtmlParser(anomaliesPnl.year_abs?.increased.title)}</p>
             <p className={`writeup-wrapper heading`}>{ReactHtmlParser(anomaliesPnl.year_abs?.decreased.title)}</p>
           </div>
           <div className={`writeups-container`}>
             <div className='anomalies-wrapper'>
               <ul>{anomaliesPnl.year_abs?.increased.value.map((buttletPoint,index) =>
                 <li key={index}>{index+1}. {ReactHtmlParser(buttletPoint)}</li>)}
                 {anomaliesPnl.year_abs?.increased.value.length === 0 && <span className='not-found-text'>No anomalies found</span>}
               </ul>
             </div>
             <div className='anomalies-wrapper'>
               <ul>{anomaliesPnl.year_abs?.decreased.value.map((buttletPoint,index) =>
                 <li key={index}>{index+1}. {ReactHtmlParser(buttletPoint)}</li>)}
                 {anomaliesPnl.year_abs?.decreased.value.length === 0 && <span className='not-found-text'>No anomalies found</span>}
               </ul>
             </div>
           </div>
         </div>
         <div className='anomalies-section-bg'>
           <div className={`writeups-container heading`}>
             <p className={`writeup-wrapper heading`}>{ReactHtmlParser(anomaliesPnl.year_cos?.increased.title_inc)}</p>
             <p className={`writeup-wrapper heading`}>{ReactHtmlParser(anomaliesPnl.year_cos?.decreased.title_dec)}</p>
           </div>
           <div className={`writeups-container`}>
             <div className='anomalies-wrapper'>
               <ul>{anomaliesPnl.year_cos?.increased.value.map((buttletPoint,index) =>
                 <li key={index}>{index+1}. {ReactHtmlParser(buttletPoint)}</li>)}
                 {anomaliesPnl.year_cos?.increased.value.length === 0 && <span className='not-found-text'>No anomalies found</span>}
               </ul>
             </div>
             <div className='anomalies-wrapper'>
               <ul>{anomaliesPnl.year_cos?.decreased.value.map((buttletPoint,index) =>
                 <li key={index}>{index+1}. {ReactHtmlParser(buttletPoint)}</li>)}
                 {anomaliesPnl.year_cos?.decreased.value.length === 0 && <span className='not-found-text'>No anomalies found</span>}
               </ul>
             </div>
           </div>
         </div>
       </div>}
      {anomaliesPnlError && <ErrorResponse error={anomaliesPnlError}/>
      }
      <hr className='separator-line' style={{margin:'2em'}}/>

      <h3 style={{fontSize:'1.2em',textAlign:'center',padding:'0.5em 0 0.5em 0',color:'#39505E'}}>Balance Sheet Anomalies</h3>
      {anomaliesBSLoading && <div className={`align-loader`}><Loader dataTestId={'anomaliesBS'} type={'pulse'} size={10}/></div>}
      {(!anomaliesBSLoading && !anomaliesBSError) &&
       <div className={`anomalies-top-wrapper`}>
         <div className='anomalies-section-bg'>
           <div className={`writeups-container heading`}>
             <p className={`writeup-wrapper heading`}>{ReactHtmlParser(anomaliesBS.month_abs?.increased.title_inc)}</p>
             <p className={`writeup-wrapper heading`}>{ReactHtmlParser(anomaliesBS.month_abs?.decreased.title_dec)}</p>
           </div>
           {anomaliesBS.month_abs &&
           <div className={`writeups-container`}>
             <div className='anomalies-wrapper'>
               <ul>{anomaliesBS.month_abs?.increased.value.map((buttletPoint,index) =>
                 <li key={index}>{index+1}. {ReactHtmlParser(buttletPoint)}</li>)}
                 {anomaliesBS.month_abs?.increased.value.length === 0 && <span className='not-found-text'>No anomalies found</span>}
               </ul>
             </div>
              <div className='anomalies-wrapper'>
                <ul>{anomaliesBS.month_abs?.decreased.value.map((buttletPoint,index) =>
                  <li key={index}>{index+1}. {ReactHtmlParser(buttletPoint)}</li>)}
                  {anomaliesBS.month_abs?.decreased.value.length === 0 && <span className='not-found-text'>No anomalies found</span>}
                </ul>
              </div>
           </div>}
         </div>
         <div className='anomalies-section-bg'>
           <div className={`writeups-container heading`}>
             <p className={`writeup-wrapper heading`}>{ReactHtmlParser(anomaliesBS.year_abs?.increased.title_inc)}</p>
             <p className={`writeup-wrapper heading`}>{ReactHtmlParser(anomaliesBS.year_abs?.decreased.title_dec)}</p>
           </div>
           {anomaliesBS.year_abs &&
            <div className={`writeups-container`}>

              <div className='anomalies-wrapper'>
                <ul>{anomaliesBS.year_abs?.increased.value.map((buttletPoint,index) =>
                  <li key={index}>{index+1}. {ReactHtmlParser(buttletPoint)}</li>)}
                  {anomaliesBS.year_abs?.increased.value.length === 0 && <span className='not-found-text'>No anomalies found</span>}
                </ul>
              </div>

              <div className='anomalies-wrapper'>
                <ul>{anomaliesBS.year_abs?.decreased.value.map((buttletPoint,index) =>
                  <li key={index}>{index+1}. {ReactHtmlParser(buttletPoint)}</li>)}
                  {anomaliesBS.year_abs?.decreased.value.length === 0 && <span className='not-found-text'>No anomalies found</span>}
                </ul>
              </div>
            </div>
           }
         </div>
       </div>}
      {anomaliesBSError && <ErrorResponse error={anomaliesBSError}/>
      }
      <hr className='separator-line' style={{margin:'2em'}}/>


      <h3 style={{fontSize:'1.2em',textAlign:'center',padding:'0.5em 0 0.5em 0',color:'#39505E'}}>CashFlow Statement Anomalies</h3>
      {anomaliesCFLoading && <div className={`align-loader`}><Loader dataTestId={'anomaliesCF'} type={'pulse'} size={10}/></div>}
      {(!anomaliesCFLoading && !anomaliesCFError) &&
       <div className={`anomalies-top-wrapper`}>
         <div className='anomalies-section-bg'>
           <div className={`writeups-container heading`}>
             <p className={`writeup-wrapper heading`}>{ReactHtmlParser(anomaliesCF.month_generation?.increased.title_inc)}</p>
             <p className={`writeup-wrapper heading`}>{ReactHtmlParser(anomaliesCF.month_consumption?.increased.title_inc)}</p>
           </div>
           <div className={`writeups-container`}>
             <div className='anomalies-wrapper'>
               <ul>{anomaliesCF.month_generation?.increased.value.map((buttletPoint,index) =>
                 <li key={index}>{index+1}. {ReactHtmlParser(buttletPoint)}</li>)}
                 {anomaliesCF.month_generation?.increased.value.length === 0 && <span className='not-found-text'>No anomalies found</span>}
               </ul>
             </div>
             <div className='anomalies-wrapper'>
               <ul>{anomaliesCF.month_consumption?.increased.value.map((buttletPoint,index) =>
                 <li key={index}>{index+1}. {ReactHtmlParser(buttletPoint)}</li>)}
                 {anomaliesCF.month_consumption?.increased.value.length === 0 && <span className='not-found-text'>No anomalies found</span>}
               </ul>
             </div>
           </div>
         </div>
         <div className='anomalies-section-bg'>
           <div className={`writeups-container heading`}>
             <p className={`writeup-wrapper heading`}>{ReactHtmlParser(anomaliesCF.month_generation?.decreased.title_dec)}</p>
             <p className={`writeup-wrapper heading`}>{ReactHtmlParser(anomaliesCF.month_consumption?.decreased.title_dec)}</p>
           </div>
           <div className={`writeups-container`}>
             <div className='anomalies-wrapper'>
               <ul>{anomaliesCF.month_generation?.decreased.value.map((buttletPoint,index) =>
                 <li key={index}>{index+1}. {ReactHtmlParser(buttletPoint)}</li>)}
                 {anomaliesCF.month_generation?.decreased.value.length === 0 && <span className='not-found-text'>No anomalies found</span>}
               </ul>
             </div>
             <div className='anomalies-wrapper'>
               <ul>{anomaliesCF.month_consumption?.decreased.value.map((buttletPoint,index) =>
                 <li key={index}>{index+1}. {ReactHtmlParser(buttletPoint)}</li>)}
                 {anomaliesCF.month_consumption?.decreased.value.length === 0 && <span className='not-found-text'>No anomalies found</span>}
               </ul>
             </div>
           </div>
         </div>

         <div className='anomalies-section-bg'>
           <div className={`writeups-container heading`}>
             <p className={`writeup-wrapper heading`}>{ReactHtmlParser(anomaliesCF.ltm_generation?.increased.title_inc)}</p>
             <p className={`writeup-wrapper heading`}>{ReactHtmlParser(anomaliesCF.ltm_consumption?.increased.title_inc)}</p>
           </div>
           <div className={`writeups-container`}>
             <div className='anomalies-wrapper'>
               <ul>{anomaliesCF.ltm_generation?.increased.value.map((buttletPoint,index) =>
                 <li key={index}>{index+1}. {ReactHtmlParser(buttletPoint)}</li>)}
                 {anomaliesCF.ltm_generation?.increased.value.length === 0 && <span className='not-found-text'>No anomalies found</span>}
               </ul>
             </div>
             <div className='anomalies-wrapper'>
               <ul>{anomaliesCF.ltm_consumption?.increased.value.map((buttletPoint,index) =>
                 <li key={index}>{index+1}. {ReactHtmlParser(buttletPoint)}</li>)}
                 {anomaliesCF.ltm_consumption?.increased.value.length === 0 && <span className='not-found-text'>No anomalies found</span>}
               </ul>
             </div>
           </div>
         </div>
         <div className='anomalies-section-bg'>
           <div className={`writeups-container heading`}>
             <p className={`writeup-wrapper heading`}>{ReactHtmlParser(anomaliesCF.ltm_generation?.decreased.title_dec)}</p>
             <p className={`writeup-wrapper heading`}>{ReactHtmlParser(anomaliesCF.ltm_consumption?.decreased.title_dec)}</p>
           </div>
           <div className={`writeups-container`}>
             <div className='anomalies-wrapper'>
               <ul>{anomaliesCF.ltm_generation?.decreased.value.map((buttletPoint,index) =>
                 <li key={index}>{index+1}. {ReactHtmlParser(buttletPoint)}</li>)}
                 {anomaliesCF.ltm_generation?.decreased.value.length === 0 && <span className='not-found-text'>No anomalies found</span>}
               </ul>
             </div>
             <div className='anomalies-wrapper'>
               <ul>{anomaliesCF.ltm_consumption?.decreased.value.map((buttletPoint,index) =>
                 <li key={index}>{index+1}. {ReactHtmlParser(buttletPoint)}</li>)}
                 {anomaliesCF.ltm_consumption?.decreased.value.length === 0 && <span className='not-found-text'>No anomalies found</span>}
               </ul>
             </div>
           </div>
         </div>
       </div>}
      {anomaliesCFError && <ErrorResponse error={anomaliesCFError}/>
      }
    </div>
  </div>
  )
}
export default Anomalies;