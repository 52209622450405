import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Modal from 'react-modal';
import './App.css';
import Header from './header/Header';
import Home from './home/Home';
import WaitForData from './wait/WaitForData';
import Login from './login/Login';
import Error from "./error/Error";
import {ToastContainer} from  'react-toastify';
import PrivacyPolicy from './privacy_policy/PrivacyPolicy';
import TermsAndConditions from './terms_of_use/TermsAndConditions';
import CookieBanner from './cookie-banner/CookieBanner';
import CookiePolicy from './cookie-policy/CookiePolicy';
import Footer from './footer/Footer';
import AnalysisPnl from './analysis-pnl/analysis-pnl';
import AnalysisBs from './analysis-bs/analysis-bs';
import Reports from './reports/Reports';
import LeftNavigation from "./left-navigation/left-navigation";
import React, {useEffect, useState} from "react";
import AnalysisCf from "./analysis-cf/analysis-cf";
import KeyInsights from "./key-insights";
import Introduction from "./introduction";
import Anomalies from "./anomalies";
import HowTo from "./how-to";
import Glossary from "./glossary";
import PageNotFound from "./page-not-found";
import TestChart from "./test-chart";
import {useSelector} from "react-redux";
import {Mixpanel} from "./mixpanel";
import _debounce from 'lodash.debounce';

let windowSize = {
  w: window.outerWidth,
  h: window.outerHeight,
  iw: window.innerWidth,
  ih: window.innerHeight
};

function App() {
  Modal.setAppElement('#root');
  const isValid= useSelector((state) => state.IsValidUser);
  const [isAuthenticated,setIsAuthenticated] = useState(isValid);

  useEffect(()=>{
    if(isValid){
      setIsAuthenticated(true);
    }
  },[isValid]);

  useEffect(()=>{
const debounceEvent= _debounce(function handleResize() {
  if (window.outerWidth !== windowSize.w || window.outerHeight !== windowSize.h) {
    windowSize.w = window.outerWidth; // update object with current window properties
    windowSize.h = window.outerHeight;
    windowSize.iw = window.innerWidth;
    windowSize.ih = window.innerHeight;
    Mixpanel.track('browser resized');
  }
  //if the window doesn't resize but the content inside does by + or - 5%
  else if (window.innerWidth + window.innerWidth * .05 < windowSize.iw) {
    Mixpanel.track('browser zoomed in');
    windowSize.iw = window.innerWidth;
  }
  else if(window.innerWidth - window.innerWidth * .05 > windowSize.iw){
    Mixpanel.track('browser zoomed out')
    windowSize.iw = window.innerWidth;
  }
}, 1000);
    window.addEventListener("resize", debounceEvent);
    return ()=>{
      window.removeEventListener('resize', debounceEvent)
    }
  });

  if(!isAuthenticated){
    return (
      <div className="qokoon-app">
        <Router>
          <ToastContainer/>
          <Switch>
            <Route exact path="/">
              <Introduction/>
              <CookieBanner/>
            </Route>
            <Route path="/cookiepolicy">
              <Header/>
              <div className="App-Wrapper">
                <CookiePolicy/>
              </div>
            </Route>
            <Route>
              <Redirect to={'/'}/>
            </Route>
          </Switch>
        </Router>
      </div>
    )
  }
  else{
    return (
      <div className="qokoon-app">
        <Router>
          <ToastContainer/>
          <Switch>
             <Route exact path="/how-to">
               <Header/>
               <div className="App-Wrapper">
                 <HowTo/>
               </div>
             </Route>
             <Route exact path="/glossary">
               <Header/>
               <div className="App-Wrapper">
                 <Glossary/>
               </div>
             </Route>
             <Route path="/wait">
               <Header/>
               <div className="App-Wrapper">
                 <WaitForData/>
               </div>
             </Route>
             <Route path="/capture-token">
               <Redirect to={"/"}/>
             </Route>

             <Route exact path="/login">
               <Login/>
               <CookieBanner/>
             </Route>

            <Route path="/cookiepolicy">
              <Header/>
              <div className="App-Wrapper">
                <CookiePolicy/>
              </div>
            </Route>

            <Route path="/privacypolicy">
              <Header/>
              <div className="App-Wrapper">
                <PrivacyPolicy/>
              </div>
            </Route>

             <Route path="/test">
               <Header/>
               <div className="App-Wrapper">
                 <TestChart/>
               </div>
             </Route>
             <Route exact path="/analyse/Profit and Loss">
               <Header/>
               <div className="App-Wrapper">
                 <LeftNavigation/>
                 <AnalysisPnl/>
               </div>
             </Route>
             <Route exact path="/analyse/Balance Sheet">
               <Header/>
               <div className="App-Wrapper">
                 <LeftNavigation/>
                 <AnalysisBs/>
               </div>
             </Route>
             <Route exact path="/analyse/CashFlow">
               <Header/>
               <div className="App-Wrapper">
                 <LeftNavigation/>
                 <AnalysisCf/>
               </div>
             </Route>
             <Route exact path="/reports">
               <Header/>
               <div className="App-Wrapper">
                 <LeftNavigation/>
                 <Reports/>
               </div>
             </Route>
             <Route exact path="/insights">
               <Header/>
               <div className="App-Wrapper">
                 <LeftNavigation/>
                 <KeyInsights/>
               </div>
             </Route>
             <Route exact path="/anomalies">
               <Header/>
               <div className="App-Wrapper">
                 <LeftNavigation/>
                 <Anomalies/>
               </div>
             </Route>}

            <Route path="/termsandconditions">
              <Header/>
              <div className="App-Wrapper">
                <TermsAndConditions/>
              </div>
            </Route>

            <Route path="/error">
              <Header/>
              <Error/>
            </Route>
            {/*<Route exact path="/">*/}
            {/*  <Login/>*/}
            {/*  <CookieBanner/>*/}
            {/*</Route>*/}
            {/* <Route exact path="/intro">*/}
            {/*   <Introduction/>*/}
            {/*   <CookieBanner/>*/}
            {/* </Route>*/}
            <Route exact path="/">
            <Header/>
            <div className="App-Wrapper">
              <Home/>
            </div>
          </Route>
            <Route component={PageNotFound} />
          </Switch>
          <Footer/>
        </Router>
      </div>
    );
  }

  // }
}

export default App;
