import { ImCross } from 'react-icons/im';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './CookieBanner.css';

function CookieBanner() {
    const [isBannerClosed, setIsBannerClosed] = useState(false);
    const [displayCookieNotice, setDisplayCookieNotice] = useState(false);
    let timer = null;

    const handleBannerClose = () => {
        setDisplayCookieNotice(false);

        timer = setTimeout(() => {
            setIsBannerClosed(true);
        }, 500);
    };

    useEffect(() => {
        setDisplayCookieNotice(true);

        if (isBannerClosed) clearTimeout(timer);
    }, [isBannerClosed, timer]);

    return !isBannerClosed && (
        <div className={`cookie-banner${displayCookieNotice ? ' open' : ''}`} data-testid="cookie-banner">
            <p id="cookie-notice">By using Qokoon online, you agree to our <Link to="/cookiepolicy" target="_blank" rel="noreferrer">use of cookies</Link> to enhance your experience.</p>
            <span id="banner-close" title="Close" onClick={handleBannerClose}><ImCross /></span>
        </div>
    );
}

export default CookieBanner;