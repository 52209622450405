import React from 'react';
import {Brush, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import Loader from "../reusables/Loader";
import ErrorResponse from "../error-response/error-response";

const CustomTooltip = (props) => {
  const { active, payload, label,formatter,categoryText } = props;
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="tooltip-heading">{`${label}`}</p>
        <div style={{color: payload[0].stroke}}>
          <span className="tooltip-label">{categoryText} : </span>
          <span className="tooltip-value">{formatter(payload[0].value)}</span>
        </div>
      </div>
    );
  }

  return null;
};

const LINE_CHART_HEIGHT = 320;
const HistoricTrendChart =({data,loading,error,formatter,categoryText='Amount',dataTestId})=>{
  if(loading) {
    return <div style={{margin:'1em 0 2em 0'}}><Loader dataTestId={`${dataTestId}-loading`} type={'pulse'} size={10}/></div>
  }
  if(data.length > 0 && !loading) {
    return (
      <div style={{ fontSize: '0.8em', marginLeft: '-20px'}} data-testid={dataTestId}>
        <ResponsiveContainer height={LINE_CHART_HEIGHT}>
          <LineChart
            height={LINE_CHART_HEIGHT}
            data={data}
            margin={{top: 5, right: 30, left: 20, bottom: 5}}
          >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="name"/>
            <YAxis tickFormatter={(value)=> formatter(value)}/>
            <Tooltip content={<CustomTooltip categoryText={categoryText}/>}  formatter={(value)=> formatter(value)}/>
            <Line type="monotone" dataKey="value" stroke="#3366CC" activeDot={{ r: 8 }} strokeWidth={3}/>
            <Brush />
          </LineChart>
        </ResponsiveContainer>
      </div>
    )
  }
  if(error){
    return (<ErrorResponse dataTestId={`${dataTestId}-error`} error={error}/>)
  }
  return <div>Data not found</div>

}
export default HistoricTrendChart;